import React, { Component } from "react";
import { Spin } from "antd";

class CommonPageLoading extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh"
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }
}

export default CommonPageLoading;
