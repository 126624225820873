import request from "utils/request";

/* 账户列表 */
export async function fetchAccountList(obj) {
  return request("/api/accounts/accounts_list", {
    method: "GET",
    data: obj
  });
}

/* 查看账户的登录账号 */
export async function fetchAccountLoginList(obj) {
  return request("/api/accounts/account_login_list", {
    method: "GET",
    data: obj
  });
}


/* 添加账户登录账号 */
export async function addLoginAccount(obj) {
  return request("/api/accounts/login_id_add", {
    method: "POST",
    data: obj
  });
}

/* 账户详情 */
export async function fetchAccountDetail(obj) {
  return request("/api/accounts/accounts_detail", {
    method: "GET",
    data: obj
  });
}

/* 删除账号 */
export async function deleteLoginDel(obj) {
  return request("/api/accounts/login_id_del", {
    method: "POST",
    data: obj
  });
}

/* 编辑账户详情 */
export async function editAccountDetail(obj) {
  return request("/api/accounts/accounts_edit", {
    method: "POST",
    data: obj
  });
}



