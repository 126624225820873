/** description: 首次登录设置密码组件
 *  last_modified: 2019-01-08
 */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Button, Divider } from "antd";
import Password from "./password";
import md5 from "blueimp-md5";

const LOGIN_STORE = "loginStore";
const SETTING_PASSWORD = "setPassword";

@inject(LOGIN_STORE)
@observer
class FirstLogin extends Component {
  handleUpdate = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props[LOGIN_STORE].fetchAction(SETTING_PASSWORD, {
          new_pwd: md5(md5(values.new_pwd, "*")),
          new_pwd_confirm: md5(md5(values.new_pwd_confirm, "*")),
          type: 1
        });
      }
    });
  };
  render() {
    return (
      <div>
        <Divider>首次登录请设置密码</Divider>
        <Form onSubmit={e => e.preventDefault()} hideRequiredMark={true}>
          <Password type="1" form={this.props.form} />
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            className="login-form-button"
            loading={this.props[LOGIN_STORE].fetchingStatus[SETTING_PASSWORD]}
            onClick={this.handleUpdate}
          >
            确认
          </Button>
        </Form>
      </div>
    );
  }
}

const FirstLoginSection = Form.create()(FirstLogin);

export default FirstLoginSection;
