import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Avatar } from "antd";
import styles from "./styles.module.css";

const STROE = "profileStore";

@inject(STROE)
@observer
class UserAvatar extends Component {
  render() {
    return (
      <div className={styles.avatar_section}>
        <div className={styles.avatar}>
          <Avatar size="small">{this.props[STROE].profileAvatar}</Avatar>
        </div>
        <div className={styles.name}>{this.props[STROE].profile.name || ''}</div>
      </div>
    );
  }
}

export default UserAvatar;
