import { action, observable } from "mobx";
import { message } from "antd";

import ModelsBase from "utils/models";
import { fetchCouponList, fetchCouponStatistics } from "services/friso_coupon";

const actionMap = {
  fetchCouponListAction: {
    api: fetchCouponList,
    action: "list",
    dataFunction: (_this, key, data) => {
      _this.listData[key] = data.result;
    },
    errorMessage: "获取整体概览数据失败"
  }
  // uploadExcelAction: {
  //   api: uploadExcel,
  //   action: "post",
  //   errorMessage: "上传文件失败"
  // }
};

class DateHeader {
  @observable
  date_type = 6; // 1: "今日",2: "本周",3: "本月",6: "全部" 5: 使用 start_time 和 end_time

  @observable
  start_time = "";

  @observable
  end_time = "";

  @action
  setDateType(v) {
    this.date_type = +v;
  }

  @action
  setTimes(startTime, endTime) {
    this.start_time = startTime;
    this.end_time = endTime;
  }

  get data() {
    return {
      date_type: this.date_type,
      start_time: this.start_time,
      end_time: this.end_time
    };
  }
}

class FrisoCouponStore extends ModelsBase {
  dateHeader = new DateHeader();

  @observable
  isLoading = false;

  @observable
  overview = {};

  @observable
  province = "";

  @observable
  city = "";

  @observable
  district = "";

  setProvince = v => {
    this.province = v;
  };

  setCity = v => {
    this.city = v;
  };

  setDistrict = v => {
    this.district = v;
  };

  setIsLoading = b => {
    this.isLoading = b;
  };

  @action.bound
  fetchCouponStatisticsAction = async () => {
    this.isLoading = true;
    try {
      const province = this.province;
      const city = this.city;
      const district = this.district;
      const { start_time, end_time, date_type } = this.dateHeader.data;
      const res = await fetchCouponStatistics({
        date_type,
        start_time,
        end_time,
        province,
        city,
        district
      });

      this.isLoading = false;
      if (res.code === 1) {
        this.overview = res.data;
      } else {
        message.info(res.message);
      }
    } catch (error) {
      this.isLoading = false;
      this.overview = {};
      message.error(error.message);
      console.log(error);
    }
  };
  // @action
  // resetTime() {
  // }
}

export default new FrisoCouponStore(actionMap);
