import { action, observable, runInAction } from "mobx";

import { message } from "antd";

class ModelsBase {
  constructor(actionMap) {
    this.actionMap = actionMap;
  }

  @observable
  fetchingStatus = {};

  @observable
  listData = {};

  @observable
  formData = {};

  @observable
  queryForm = {};

  /* 网络请求 */
  @action
  async fetchAction(key, data) {
    const action = this.actionMap[key];
    const type = action.type ? action.type : "get";

    const defaultFetchListFunction = () => {
      return Object.assign({}, this.queryForm[key], {
        cur_page: data && data.cur_page ? data.cur_page : 1
      });
    };

    if (action === undefined) {
      return false;
    }
    if (type.toLowerCase() === "post" && this.fetchingStatus[key]) {
      return false;
    }
    this.fetchingStatus[key] = true;
    let result = {};
    if (action.action === "list") {
      action.fetchAction = defaultFetchListFunction;
    }
    try {
      const reqData =
        typeof action.fetchAction === "function" ? action.fetchAction() : data;
      const res = await action.api(reqData);
      this.fetchingStatus[key] = false;
      runInAction(() => {
        if (res) {
          const resData = res.data;
          if (typeof action.dataFunction === "function") {
            action.dataFunction(this, key, resData, reqData);
          }
        }
      });
      result = res;
    } catch (error) {
      console.error(error);
      message.error(error.message);
      message.error(action.errorMessage ? action.errorMessage : "网络请求失败");
      this.fetchingStatus[key] = false;
      result = error;
    }
    return result;
  }

  @action
  /* 跟新列表搜索表单 */
  updateQueryFormAction(key, data) {
    this.queryForm[key] = data;
  }

  @action
  /* 全量更新对象 */
  updateFormAction(key, data) {
    this.formData[key] = data;
  }

  @action
  /* 合并更新对象 */
  mergeFormAction(key, data) {
    this.formData[key] = Object.assign(
      this.formData[key] ? this.formData[key] : {},
      data
    );
  }

  @action
  /* 重置所有状态 */
  resetAction() {
    this.fetchingStatus = {};
    this.queryForm = {};
    this.formData = {};
    this.listData = {};
  }
}

export default ModelsBase;
