/** description: 用户名密码登录组件
 *  last_modified: 2019-01-08
 */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import md5 from "blueimp-md5";

const FormItem = Form.Item;

const STORE = "loginStore";

const LOGIN_ACTION = "login";

@inject(STORE)
@observer
class UserForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props[STORE].fetchAction(LOGIN_ACTION, {
          mobile: values.mobile,
          pwd: md5(md5(values.pwd, "*")),
          type: 2
        });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem>
            {getFieldDecorator("mobile", {
              rules: [{ required: true, message: "请输入您的用户名" }]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                size="large"
                type="text"
                placeholder="账号/手机号"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("pwd", {
              rules: [{ required: true, message: "请输入密码" }]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                size="large"
                type="password"
                placeholder="密码"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("auto")(<Checkbox>自动登录</Checkbox>)}
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              className="login-form-button"
              disabled={this.props[STORE].fetchingStatus[LOGIN_ACTION]}
              loading={this.props[STORE].fetchingStatus[LOGIN_ACTION]}
              onClick={this.handleSubmit}
            >
              登录
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const UserFormSection = Form.create()(UserForm);

export default UserFormSection;
