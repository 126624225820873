import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Dropdown, Menu, Modal, Icon } from "antd";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import UserAvatar from "../UserAvatar";
import RoleModal from "./roleModal";

const confirm = Modal.confirm;

const LOGIN_STORE = "loginStore";
const UI_STORE = "uiStore";
const PROFILE_STORE = "profileStore";

const LOGOUT_ACTION = "logout";

@inject(UI_STORE)
@inject(LOGIN_STORE)
@inject(PROFILE_STORE)
@observer
class CommonHeader extends Component {
  handleToggleSlider = () => {
    this.props[UI_STORE].toggleSliderAction();
  };
  handleLogout = () => {
    confirm({
      title: "是否要登出系统",
      content: "下次访问需要重新登录",
      okText: "登出",
      cancelText: "取消",
      onOk: () => {
        this.props[LOGIN_STORE].fetchAction(LOGOUT_ACTION);
      }
    });
  };
  handleChangeRole = () => {
    this.props[PROFILE_STORE].handleToggleModalShow();
  };
  render() {
    const accountList = this.props[PROFILE_STORE].profile.account_list;

    let icon;
    if (this.props[UI_STORE].slideOpen) {
      icon = <Icon type="menu-unfold" theme="outlined" />;
    } else {
      icon = <Icon type="menu-fold" theme="outlined" />;
    }
    const DropdownMenu = (
      <Menu>
        <Menu.Item key="setting">
          <Link to="/account/setting">
            <Icon type="setting" theme="outlined" style={{ marginRight: 10 }} />
            个人设置
          </Link>
        </Menu.Item>
        {accountList && Object.keys(accountList).length > 0 ? (
          <Menu.Item key="role">
            <span onClick={this.handleChangeRole}>
              <Icon type="user" theme="outlined" style={{ marginRight: 10 }} />
              切换账户
            </span>
            <RoleModal />
          </Menu.Item>
        ) : null}
        <Menu.Item>
          <span onClick={this.handleLogout}>
            <Icon type="logout" theme="outlined" style={{ marginRight: 10 }} />
            退出登录
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className={styles.header}>
        <div className={styles.left_section} onClick={this.handleToggleSlider}>
          {icon}
        </div>
        <div className={styles.right_section}>
          <Dropdown
            overlay={DropdownMenu}
            trigger={["click", "hover"]}
            placement="bottomCenter"
          >
            <div>
              <UserAvatar />
            </div>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default CommonHeader;
