const prefix = process.env.REACT_APP_LOCAL_STORAGE_PREFIX;

/* 统一前缀 */
const config = {
  prefix: prefix + "_"
};

const storage = window.localStorage;

/* 检测是否支持LS */
const isSupported = () => {
  let supported = false;
  if (storage && storage.setItem) {
    supported = true;
    const key = "__" + Math.round(Math.random() * 1e7);
    try {
      //隐身模式有setItem方法，可是不能调用
      storage.setItem(key, key);
      storage.removeItem(key);
    } catch (err) {
      supported = false;
    }
  }
  return supported;
};

class LocalStorage {
  static caniuse() {
    return isSupported();
  }

  static setData(key, data, expires) {
    // expires 是时间戳
    if (data === undefined || data === null) return false;
    data = {
      data: data
    };
    if (expires !== undefined) {
      data.expires = expires;
    }
    storage.setItem(config.prefix + key, JSON.stringify(data));
  }

  static getData(key) {
    if (key === undefined || key === null) return false;
    const data = storage.getItem(config.prefix + key);
    const expires = data ? JSON.parse(data).expires : null;
    const now = Date.now();
    if (data && (!expires || now < +new Date(expires))) {
      return JSON.parse(data).data;
    } else {
      return null;
    }
  }

  static clearData(key) {
    storage.removeItem(config.prefix + key);
  }

  static clearAll() {
    storage.clear();
  }
}

export default LocalStorage;
