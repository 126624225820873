/*
目前权限鉴定只支持一级菜单，二级菜单不支持。
need_authority 表示是否需要进行权限鉴定
和接口返回的path进行匹配，符合的表示具有该路径的所有页面的权限
routes表示有子页面/子菜单。
子页面是否需要在左侧菜单展示，根据type判断，如果是menu则表示需要展示，child表示不需要
*/

/* 业务页面 */
const PageRoutes = [
  {
    name: "申领码管理",
    icon: "account-book",
    path: "/coupon",
    type: "menu",
    need_authority: true
  },
  {
    name: "数据统计",
    icon: "bar-chart",
    path: "/statistics",
    type: "menu",
    need_authority: false
  },
  {
    name: "样本管理",
    icon: "profile",
    path: "/sample",
    type: "menu",
    need_authority: true,
    routes: [
      {
        name: "样本详情",
        path: "/sample/detail",
        type: "child"
      }
    ]
  },
  {
    name: "成员管理",
    icon: "user",
    path: "/user",
    type: "menu",
    need_authority: true,
    routes: [
      // {
      //   name: "成员列表",
      //   path: "/user/member",
      //   type: "menu",
      //   need_authority: true
      // },
      // {
      //   name: "团队列表",
      //   path: "/user/team",
      //   type: "menu",
      //   need_authority: true
      // }
    ]
  },
  {
    name: "合作管理",
    icon: "team",
    path: "/cooperate/channel",
    type: "menu",
    need_authority: true,
    routes: [
      // {
      //   name: "合作渠道管理",
      //   path: "/cooperate/channel",
      //   type: "menu",
      //   need_authority: true
      // },
      {
        name: "新增合作渠道",
        path: "/cooperate/channel_add",
        type: "child",
        need_authority: true
      },
      {
        name: "新增渠道商品",
        path: "/cooperate/good_add",
        type: "child",
        need_authority: true
      },
      {
        name: "新增活动配置",
        path: "/cooperate/activity_add",
        type: "child",
        need_authority: true
      }
    ]
  },
  {
    name: "系统设置",
    icon: "setting",
    path: "/setting",
    type: "menu",
    need_authority: true,
    routes: [
      {
        name: "合作机构管理",
        path: "/setting/hospital",
        type: "menu",
        need_authority: true
      },
      {
        name: "新增合作机构",
        path: "/setting/hospital_add",
        type: "child",
        need_authority: true
      },
      {
        name: "账户管理",
        path: "/setting/account",
        type: "menu",
        need_authority: true
      },
      {
        name: "账户详情",
        path: "/setting/account_detail",
        type: "child",
        need_authority: false
      }
    ]
  },
  {
    name: "个人设置",
    path: "/account/setting",
    type: "system",
    need_authority: false
  },
  {
    name: "协助管理",
    path: "/help",
    type: "child",
    need_authority: true
  }
];

export default PageRoutes;
