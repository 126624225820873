import { observable, action, runInAction, autorun, toJS, computed } from "mobx";
import dayjs from "dayjs";
import { message } from "antd";
import LocalStorage from "utils/localstorage";
import { fetchAuthorityList } from "services/profile";
import pageRoutes from "routes/page"; // page中定义的路由
import baseRoutes from "routes/base";
import baseComponents from "routes/base_components";
import pageComponents from "routes/page_components";
import Ui from "./ui";
import Router from "./router";
import ModelsBase from "utils/models";

/* 本地存储用户相关信息 */
const AUTHORITY_MAX_AGE = +process.env.REACT_APP_LS_CACHE_DAYS; // 单位 : 天
const AUTHORITY_LS_KEY = "authority_list";

const allComponents = baseComponents.concat(pageComponents);

class AuthorityStore extends ModelsBase {
  constructor(list) {
    super();
    this.authorityList = list;
  }

  @observable
  authorityList = [];

  /* 更新授权列表 */
  @action
  updateAuthorityListAction(list) {
    if (list && list.length > 0) {
      this.authorityList = this._flattenAuthorityList(list);
      LocalStorage.setData(
        AUTHORITY_LS_KEY,
        this.authorityList,
        dayjs().add(AUTHORITY_MAX_AGE, "day")
      );
    } else {
      this.fetchAuthorityListAction();
    }
  }

  _flattenAuthorityList = (authorityList, arr = []) => {
    const _flatten = list => {
      list.forEach(item => {
        if (item.son && Array.isArray(item.son)) {
          arr.push({
            name: item.name,
            route: item.route,
            allow: item.allow
          });
          _flatten(item.son);
        } else {
          arr.push(item);
        }
      });
    };

    _flatten(authorityList);
    return arr;
  };

  /* 获取授权列表 */
  @action
  async fetchAuthorityListAction() {
    try {
      const res = await fetchAuthorityList();
      runInAction(() => {
        if (
          res &&
          res.data &&
          res.data.access_list &&
          res.data.access_list.length > 0
        ) {
          const list = this._flattenAuthorityList(res.data.access_list);
          this.updateAuthorityListAction(list);
        } else {
          message.error("无法更新权限列表，请刷新重试");
        }
      });
    } catch (error) {
      console.log(error);
      message.error("无法更新权限列表，请刷新重试");
    }
  }

  /* 清楚授权列表 */
  @action
  clearAuthorityListAction() {
    this.authorityList = [];
    LocalStorage.clearData(AUTHORITY_LS_KEY);
  }

  _isInAuthorityList(path) {
    return toJS(this.authorityList).findIndex(i => i.route === path) !== -1;
  }

  _check(path) {
    return allComponents.some(i => i.path === path);
  }

  @computed
  get authorityRouteList() {
    let pageRoute = [];

    pageRoutes.forEach(item => {
      if (!item.need_authority) {
        pageRoute.push(item);
      } else if (item.need_authority && this._isInAuthorityList(item.path)) {
        if (item.routes && item.routes.length > 0) {
          pageRoute.push({
            name: item.name,
            icon: item.icon,
            type: item.type,
            path: item.path,
            need_authority: item.need_authority,
            routes: item.routes.filter(route => {
              return (
                !route.need_authority ||
                (route.need_authority && this._isInAuthorityList(route.path))
              );
            })
          });
        } else {
          pageRoute.push({
            name: item.name,
            icon: item.icon,
            type: item.type,
            path: item.path,
            need_authority: item.need_authority
          });
        }
      }
    });
    return pageRoute;
  }

  @computed
  get makeMenuList() {
    const list = this.authorityRouteList.map(item => {
      const res = Object.assign({}, item);
      if (res.routes && res.routes.length > 0) {
        res.routes = res.routes.filter(i => {
          return i.type === "menu";
        });
      }
      return res;
    });
    return []
      .concat(baseRoutes.filter(i => i.type === "menu"))
      .concat(list.filter(i => i.type === "menu"));
  }

  @computed
  get makeRouteList() {
    let list = [];
    this.authorityRouteList.forEach(item => {
      if (item.routes && item.routes.length > 0) {
        item.routes.forEach(i => list.push(i));
      }
      list.push(item);
    });
    return list.filter(i => {
      return this._check(i.path);
    });
  }

  /* autorun */
  autoAuthorityList = autorun(() => {
    if (this.authorityList.length > 0) {
      Ui.updateMenuListAction(this.makeMenuList);
      Router.updateRouterListAction(this.makeRouteList);
    }
  });
}

const authorityLS = LocalStorage.getData(AUTHORITY_LS_KEY);

export default new AuthorityStore(authorityLS ? authorityLS : []);
