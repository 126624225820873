import { observable, action, runInAction } from "mobx";
import { fetchReport } from "services/report";
import { message } from "antd";

class ReportStore {
  @observable
  report = {};

  @observable
  loaded = false;

  @action
  async fetchReportAction(obj) {
    let result = {};
    try {
      const res = await fetchReport(obj);
      runInAction(() => {
        if (res && res.data) {
          this.report = res.data;
          this.loaded = true;
        } else {
          message.error("无法更新权限列表，请刷新重试");
        }
      });
      result = res;
    } catch (error) {
      message.error("无法获取报告详情");
      result = error;
    }
    return result;
  }

  @action
  resetAction() {
    this.loaded = false;
    this.report = {};
  }
}

export default new ReportStore();
