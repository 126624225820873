import authorityStore from "./authority";
import cooperateStore from "./cooperate/index";
import frisoCouponStore from "./friso_coupon/index";
import hospitalStore from "./hospital/index";
import loginStore from "./login";
import profileStore from "./profile";
import qpcrReportStore from "./qpcr_report/index";
import reportStore from "./report/index";
import routerStore from "./router";
import sampleStore from "./sample/index";
import statisticStore from "./statistics/index";
import accountStore from "./account/index";
import accountManageStore from "./account_manage/index";
import uiStore from "./ui";
import userStore from "./user/index";

const stores = {
  routerStore,
  uiStore,
  loginStore,
  profileStore,
  authorityStore,
  userStore,
  hospitalStore,
  sampleStore,
  reportStore,
  cooperateStore,
  qpcrReportStore,
  statisticStore,
  accountStore,
  accountManageStore,
  frisoCouponStore
};

export default stores;
