import request from "utils/request";

/* 获取团队 */
export async function fetchTeam(obj) {
  return request("/api/accounts/number_list_init", {
    method: "GET",
    data: obj
  });
}

/* 编辑/新增团队 */
export async function putTeam(obj) {
  return request("/api/accounts/team_manage", {
    method: "POST",
    data: obj
  });
}

/* 新增团队成员 */
export async function addMember(obj) {
  return request("/api/accounts/add_number", {
    method: "POST",
    data: obj
  });
}

/* 修改团队成员 */
export async function editMember(obj) {
  return request("/api/accounts/edit_number", {
    method: "POST",
    data: obj
  });
}

/* 成员列表 */
export async function fetchMemberList(obj) {
  return request("/api/accounts/account_number_list", {
    method: "GET",
    data: obj
  });
}

/* 禁用/启用成员 */
export async function updateMemberStatus(obj) {
  return request("/api/accounts/update_number_status", {
    method: "POST",
    data: obj
  });
}
