import request from "utils/request";

/* 获取列表 */
export async function fetchSampleList(obj) {
  return request("/api/sample", {
    method: "GET",
    data: obj
  });
}

/* 获取统计 */
export async function fetchSampleSum() {
  return request("/api/sample/statistics", {
    method: "GET"
  });
}

/* 获取样本详情 */
export async function fetchSampleDetail(id) {
  return request("/api/sample/sample_info", {
    method: "GET",
    data: {
      id: id
    }
  });
}

/* 获取科室列表 */
export async function fetchDepartmentList(hospital_id) {
  return request("/api/sample/department_list", {
    method: "GET",
    data: {
      hospital_id: hospital_id
    }
  });
}
