import { observable, action } from "mobx";
import { message } from "antd";
import {
  fetchUserList,
  addUser,
  editUser,
  addTeam,
  fetchTeamList,
  editTeamStatus,
  editUserStatus
} from "services/user";
import ModelsBase from "utils/models";

const userFormTemplate = {
  id: undefined,
  name: undefined,
  mobile: undefined,
  role: undefined,
  team_id: undefined
};

const teamFormTemplate = {
  id: undefined,
  name: undefined
};

const actionMap = {
  userList: {
    api: fetchUserList,
    action: "list",
    dataFunction: (_this, key, data) => {
      _this.listData[key] = data.user_data;
      _this.userStatusList = data.status_list;
      _this.userRoleList = data.role_list;
      _this.userTeamList = data.team_list;
    }
    // errorMessage: "获取用户列表错误"
  },
  teamList: {
    api: fetchTeamList,
    action: "list",
    dataFunction: (_this, key, data) => {
      _this.listData[key] = data.data;
      _this.userStatusList = data.status_list;
      _this.teamList = data.team_list;
    }
    // errorMessage: "获取团队列表错误"
  },
  addTeamAction: {
    api: addTeam,
    type: "post",
    dataFunction: () => {
      message.success("新增团队成功");
    },
    errorMessage: "新增团队失败"
  },
  editTeamAction: {
    api: addTeam,
    type: "post",
    dataFunction: () => {
      message.success("编辑团队成功");
    },
    errorMessage: "编辑团队失败"
  },
  editTeamStatus: {
    api: editTeamStatus,
    type: "post",
    dataFunction: () => {
      message.success("操作成功");
    },
    errorMessage: "编辑团队状态失败"
  },
  editUserStatus: {
    api: editUserStatus,
    type: "post",
    dataFunction: () => {
      message.success("操作成功");
    },
    errorMessage: "编辑成员状态失败"
  },
  addUserAction: {
    api: addUser,
    type: "post",
    dataFunction: () => {
      message.success("新增用户成功");
    },
    errorMessage: "新增用户失败"
  },
  editUserAction: {
    api: editUser,
    type: "post",
    dataFunction: () => {
      message.success("编辑用户信息成功");
    },
    errorMessage: "编辑用户信息失败"
  }
};

class UserStore extends ModelsBase {
  /* ========= User ========= */

  @observable
  roleType = "";

  // 协助管理的参数
  @observable
  help_id = "";

  @observable
  userStatusList = {};

  @observable
  userRoleList = {};

  @observable
  userTeamList = {};

  @observable
  teamList = {};

  /** 抽屉是否可见 */
  @observable
  drawerVisible = false;

  @observable
  addTeamVisible = false;

  @observable
  addUserForm = Object.assign({}, userFormTemplate);

  @observable
  addTeamForm = Object.assign({}, teamFormTemplate);

  /* 保存表单 */
  @action
  saveToUserFormAction(data) {
    this.addUserForm = Object.assign(this.addUserForm, data);
  }

  /* 保存表单 */
  @action
  saveToTeamFormAction(data) {
    this.addTeamForm = Object.assign(this.addTeamForm, data);
  }
  /** 新增成员弹窗是否可见 */
  @action
  toggleDrawerVisible() {
    this.drawerVisible = !this.drawerVisible;
  }

  // 设置协助管理的参数id
  @action
  settingHelpId(id) {
    this.help_id = id;
  }

  /**  新增团队弹窗是否可见 */
  @action
  toggleModalVisible() {
    this.addTeamVisible = !this.addTeamVisible;
  }

  /* 重置表单和步骤 */
  @action
  resetAddUserAction() {
    this.addUserForm = Object.assign({}, userFormTemplate);
    this.roleType = "";
  }

  /** 切换账户 */
  @action
  toggleRoleType(value) {
    this.roleType = value;
  }

  /* 重置表单和步骤 */
  @action
  resetAddTeamAction() {
    this.addTeamForm = Object.assign({}, teamFormTemplate);
  }
}

export default new UserStore(actionMap);
