import React, { Component } from "react";
import { Layout } from "antd";
import styles from "./UserLayout.module.css";

class UserLayout extends Component {
  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <div className={styles.layout}>{{ ...this.props.children }}</div>
      </Layout>
    );
  }
}

export default UserLayout;
