import React, { Component } from "react";
import { Layout, LocaleProvider } from "antd";
import CommonHeader from "components/CommonHeader";
import CommonFooter from "components/CommonFooter";
import CommonMenu from "components/CommonMenu";
import zhCN from "antd/lib/locale-provider/zh_CN";

const { Header, Content, Footer } = Layout;

class BasicLayout extends Component {
  render() {
    return (
      <LocaleProvider locale={zhCN}>
        <Layout style={{ minHeight: "100vh" }}>
          <CommonMenu />
          <Layout>
            <Header style={{ padding: "0" }}>
              <CommonHeader />
            </Header>
            <Content>{{ ...this.props.children }}</Content>
            <Footer>
              <CommonFooter />
            </Footer>
          </Layout>
        </Layout>
      </LocaleProvider>
    );
  }
}

export default BasicLayout;
