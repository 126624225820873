import { observable, action, runInAction, computed } from "mobx";
import { message } from "antd";
import {
  putTeam,
  fetchTeam,
  addMember,
  editMember,
  fetchMemberList,
  updateMemberStatus
} from "services/account";

class TableColumn {
  id = "";
  @observable number_name = "";
  @observable mobile = "";
  @observable status = "";

  constructor(o) {
    this.id = o.id;
    this.number_name = o.number_name;
    this.mobile = o.mobile;
    this.status = o.status;
  }

  @computed
  get statusText() {
    switch (this.status) {
      case "1":
        return "正常";
      case "2":
        return "禁用";
      default:
        return "";
    }
  }

  @action.bound
  updateMemberStatus = async ({ number_id, status }) => {
    try {
      const res = await updateMemberStatus({ number_id, status });
      if (res.code === 1) {
        runInAction(() => {
          this.status = status;
          console.log(this.status);
        });
        message.success(res.data.mesg);
      } else {
        message.info(res.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
}

class AccountStore {
  /* ========= User ========= */

  /** table 的状态 */
  @observable
  table = {
    data: [],
    total: 0,
    isLoading: false,
    query: {
      team_id: "",
      cur_page: "",
      number_name: "",
      mobile: "",
      page_size: 10
    }
  };

  @action.bound
  fetchMemberListAction = async () => {
    try {
      this.table.isLoading = true;
      const data = await fetchMemberList(this.table.query);

      if (data.code === 1) {
        const { items, total } = data.data;

        runInAction(() => {
          this.table.isLoading = false;
          this.table.total = total;

          if (Array.isArray(items)) {
            const tableData = items.map(item => new TableColumn(item));

            this.table.data = tableData;
          } else {
            this.table.data = [];
          }
        });
      } else {
        this.table.isLoading = false;
      }
    } catch (error) {
      this.table.isLoading = false;
      message.error(error.message);
    }
  };

  @action.bound
  setCurrentPage = page => {
    this.table.query.cur_page = page;
  };

  @action.bound
  resetFormFields = () => {
    this.table.query.mobile = "";
    this.table.query.number_name = "";
    this.setCurrentPage(1);
  };

  @action.bound
  setTableQueryMobile = mobile => {
    this.table.query.mobile = mobile;
  };

  @action.bound
  setTableQueryNumberName = numberName => {
    this.table.query.number_name = numberName;
  };

  /* ---- 团队名称 ---- */
  @observable
  teamInfo = {
    isVisible: false, // 增加/编辑团队的 modal
    team_id: "",
    submit_token: "",
    team_name: ""
  };

  @action
  setTeamInfoName(name) {
    this.teamInfo.team_name = name;
  }

  @action
  resetTeamModal() {
    this.teamInfo.team_name = "";
  }

  @action
  toggleModalVisible() {
    this.teamInfo.isVisible = !this.teamInfo.isVisible;
  }

  @action.bound
  fetchTeamAction = async () => {
    try {
      const res = await fetchTeam();
      if (res.code === 1) {
        runInAction(() => {
          if (res.data) {
            const { team_name, id } = res.data;
            this.teamInfo.team_name = team_name;
            this.teamInfo.team_id = id;

            this.table.query.team_id = id;

            this.modalMember.form.team_id = id;
          } else {
            this.teamInfo.team_name = "";
            this.teamInfo.team_id = "";
            this.table.query.team_id = "";
            this.modalMember.form.team_id = "";
          }
        });
      } else {
        message.info(res.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  @action.bound
  putTeamAction = async o => {
    try {
      const res = await putTeam(o);

      if (res.code === 1) {
        if (o.team_id) {
          message.success("编辑成功");
        } else {
          this.table.query.team_id = res.data.id;
          this.modalMember.form.team_id = res.data.id;
          this.teamInfo.team_id = res.data.id;
          message.success("创建成功");
          this.fetchMemberListAction();
        }

        runInAction(() => {
          this.teamInfo.isVisible = false;
          this.teamInfo.team_name = o.team_name;
        });
      } else {
        message.success("编辑失败");
        this.teamInfo.isVisible = true;
      }
    } catch (error) {
      this.teamInfo.isVisible = true;
      console.log(error);
      message.error(error.message);
      throw error;
    }
  };

  /* 编辑成员modal */
  @observable
  modalMember = {
    isShow: false,
    form: {
      submit_token: "",
      number_name: "",
      mobile: "",
      team_id: "",
      number_id: ""
    }
  };

  @action
  toggleMemberModalShow = () => {
    this.modalMember.isShow = !this.modalMember.isShow;
  };

  @action
  setModalMemberForm = ({ number_name, mobile, id }) => {
    this.modalMember.form.number_name = number_name;
    this.modalMember.form.mobile = mobile;
    this.modalMember.form.number_id = id;
  };

  @action.bound
  resetModalMemberForm = () => {
    this.modalMember.form.number_name = "";
    this.modalMember.form.mobile = "";
  };

  @action.bound
  addMemberAction = async data => {
    try {
      const res = await addMember(data);
      if (res.code === 1) {
        message.success(res.data.mesg);
        runInAction(() => {
          this.resetModalMemberForm();

          // 添加成功 ，刷新table
          this.fetchMemberListAction();
        });
      } else {
        message.success(res.message);
      }
    } catch (error) {
      message.error(error.message);
      throw error;
    }
  };

  @action.bound
  editMemberAction = async data => {
    try {
      const res = await editMember(data);
      if (res.code === 1) {
        message.success(res.data.mesg);
        runInAction(() => {
          this.resetModalMemberForm();

          // 添加成功 ，刷新table
          this.fetchMemberListAction();
        });
      } else {
        message.success(res.message);
      }
    } catch (error) {
      message.error(error.message);
      throw error;
    }
  };
}

export default new AccountStore();
