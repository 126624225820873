import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Tabs } from "antd";
import LoginPhoneSection from "./phone.js"; //手机号登录组件
import UserFormSection from "./user.js"; // 账号密码登录组件
import FirstLoginSection from "./first.js"; // 首次登录设置密码组件

const TabPane = Tabs.TabPane;

const STORE = "loginStore";

@inject(STORE)
@observer
class LoginSection extends Component {
  render() {
    if (this.props[STORE].isFirstLogin === 1) {
      return <FirstLoginSection />;
    }
    return (
      <Tabs defaultActiveKey="1" tabBarGutter={100}>
        <TabPane tab="账号密码登录" key="1">
          <UserFormSection />
        </TabPane>
        <TabPane tab="手机号登录" key="2">
          <LoginPhoneSection />
        </TabPane>
      </Tabs>
    );
  }
}

export default LoginSection;
