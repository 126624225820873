import React, { Component } from "react";
import { Layout } from "antd";

class BlankLayout extends Component {
  render() {
    return (
      <Layout style={{ minHeight: "100vh", justifyContent: "center" }}>
        {{ ...this.props.children }}
      </Layout>
    );
  }
}

export default BlankLayout;
