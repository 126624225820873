import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import BasicLayout from "layouts/BasicLayout";
import PageTitle from "components/PageTitle";
// import SampleSum from "./sum";
import { Icon } from "antd";
import style from "./style.module.css";

const STORE = "profileStore";

@inject(STORE)
@observer
class DashBoard extends Component {
  render() {
    const contact_info = this.props[STORE].profile.contact_info;
    return (
      <BasicLayout>
        <div>
          <PageTitle title="概览">
            {this.props[STORE].profileHello}
            {contact_info && contact_info.name ? (
              <div className={style.contact}>
                <p>
                  <Icon type="user" />
                  专属对接人:{contact_info.name}
                </p>
                <p>
                  <Icon type="phone" />
                  联系方式:{contact_info.mobile}
                </p>
              </div>
            ) : null}
          </PageTitle>
          <div style={{ margin: "24px" }}>
            {/* <SampleSum /> */}
          </div>
        </div>
      </BasicLayout>
    );
  }
}

export default DashBoard;
