import React, { Component } from "react";
import styles from "./styles.module.css";

class CommonFooter extends Component {
  render() {
    return (
      <div className={styles.footer}>知几未来 ©2019 Created by Nexbrio</div>
    );
  }
}

export default CommonFooter;
