import request from "utils/request";

/* 获取报告 */
export async function fetchReport(obj) {
  return request("/api/sample/get_report_info", {
    method: "GET",
    data: {
      id: obj.id,
      preview: obj.preview
    }
  });
}


/* 获取短信推送的报告 */
export async function fetchMessageReports(obj) {
  return request("/api/report/get_channel_report_list", {
    method: "GET",
    data: obj
  });
}