import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import withRouterStore from "components/HOC/withRouterStore";
import baseComponents from "routes/base_components";
import pageComponents from "routes/page_components";

const componentsList = baseComponents.concat(pageComponents);

@inject("authorityStore")
@inject("routerStore")
@inject("loginStore")
@observer
class Authority extends Component {
  getComponent(path) {
    return componentsList.find(i => i.path === path).component;
  }
  needLogin() {
    const noNeedLoginList = [
      "/message_report",
      "/report",
      "/report_v2",
      "/report_v3",
      "/qpcr_report",
      "/qpcr_report_v2",
      "/report_pathogen",
      "/report_urogenital",
      "/report_ncov",
      "/report_friso",
      "/report_quantum",
      "/tools",
      "/report_qpcr_chigenyi"
    ]; // 免登陆列表
    const path = window.location.pathname;
    return noNeedLoginList.indexOf(path) === -1;
  }
  render() {
    if (!this.props.loginStore.isLogin && this.needLogin()) {
      return (
        <Router>
          <Route
            key="/login"
            path=""
            component={withRouterStore(this.props.routerStore)(
              this.getComponent("/login")
            )}
          />
        </Router>
      );
    }
    return (
      <Router>
        <Switch>
          {this.props.routerStore.routerList.map((route, index) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={withRouterStore(this.props.routerStore)(
                this.getComponent(route.path)
              )}
            />
          ))}
        </Switch>
      </Router>
    );
  }
}

export default Authority;
