import request from "utils/request";

/* 发送登录短信验证码 */
export async function sendMobileCode(obj) {
  return request("/api/login/send_code", {
    method: "POST",
    data: obj
  });
}

/* 登录 */
export async function login(obj) {
  return request("/api/login", {
    method: "POST",
    data: obj
  });
}

/* 登出 */
export async function logout() {
  return request("/api/login/logout", {
    method: "GET"
  });
}

/* 登录 */
export async function setPassword(obj) {
  return request("/api/user/set_pwd", {
    method: "POST",
    data: obj
  });
}

/* 登录状态下修改密码 */
export async function setPasswordWithLogin(obj) {
  return request("/api/login/set_password", {
    method: "POST",
    data: obj
  });
}
