import { observable, autorun } from "mobx";
import dayjs from "dayjs";
import { message } from "antd";
import LocalStorage from "utils/localstorage";
import {
  sendMobileCode,
  login,
  logout,
  setPassword,
  setPasswordWithLogin
} from "services/login";
import ModelsBase from "utils/models";
import profileStore from "./profile";
import authorityStore from "./authority";

/* 倒计时 */
const COUNT_DOWN_TIME = 60;

/* 本地存储登录相关信息 */
const LOGIN_STATUS_MAX_AGE = +process.env.REACT_APP_LS_CACHE_DAYS; // 单位 : 天

const LOGIN_LS_KEY = "isLogin";
const ACCOUNT_LS = "account_list";

const isLoginLS = Boolean(LocalStorage.getData(LOGIN_LS_KEY));

const actionMap = {
  sendMobileCode: {
    api: sendMobileCode,
    type: "post",
    dataFunction: (_this, key, data) => {
      message.success("验证码发送成功");
      _this.canSendMobileCode = false;
      _this._startCountDown();
    }
  },
  login: {
    api: login,
    type: "post",
    dataFunction: (_this, key, data) => {
      // message.success("登录成功，正在为您跳转");
      if (data.access_list) {
        _this.authorityList = data.access_list;
        _this.isFirstLogin = Number(data.first_flag); // first_flag，第一次登录，要修改密码，否则直接跳转
        _this.accountList = data.account_list;
      }
      // authorityStore.fetchAuthorityListAction();

      // 第一次登录，不设置登录状态
      if (_this.isFirstLogin === 0) {
        _this.isLogin = true;
        LocalStorage.setData(
          LOGIN_LS_KEY,
          true,
          dayjs().add(LOGIN_STATUS_MAX_AGE, "day")
        );
        LocalStorage.setData(
          ACCOUNT_LS,
          _this.accountList,
          dayjs().add(LOGIN_STATUS_MAX_AGE, "day")
        );
      }
    }
  },
  logout: {
    api: logout,
    type: "post",
    dataFunction: (_this, key, data) => {
      message.success("退出登录成功，正在为您跳转");
      _this.isLogin = false;
      LocalStorage.clearData(LOGIN_LS_KEY);
    }
  },
  setPassword: {
    api: setPassword,
    type: "post",
    dataFunction: (_this, key, data) => {
      message.success("密码设置成功");
      _this.isFirstLogin = null;
      _this.isLogin = true;
      LocalStorage.setData(
        LOGIN_LS_KEY,
        true,
        dayjs().add(LOGIN_STATUS_MAX_AGE, "day")
      );
      LocalStorage.setData(
        ACCOUNT_LS,
        _this.accountList,
        dayjs().add(LOGIN_STATUS_MAX_AGE, "day")
      );
    }
  },
  setPasswordWithLogin: {
    api: setPasswordWithLogin,
    type: "post",
    dataFunction: (_this, key, data) => {
      message.success("密码修改成功，请重新登录");
      _this.isLogin = false;
      LocalStorage.clearData(LOGIN_LS_KEY);
    }
  }
};

class LoginStore extends ModelsBase {
  constructor(actionMap, isLogin) {
    super(actionMap);
    this.isLogin = isLogin;
  }

  /* 计时器 */
  COUNT_DOWN_TIMER;

  /* 个人设置页面的tab选项状态 */
  @observable
  defaultActiveKey = "3";

  /* 登录状态 */
  @observable
  isLogin = false;

  @observable
  isFirstLogin = null;

  /** 该用户的角色列表 */
  @observable
  accountList = {};

  @observable
  authorityList = [];

  /* 验证码 */
  @observable
  canSendMobileCode = true;

  @observable
  sendMobileCodeCountDown = 60;

  /* 倒计时 */
  _startCountDown() {
    if (this.sendMobileCodeCountDown === 0) {
      this.sendMobileCodeCountDown = COUNT_DOWN_TIME;
      this.canSendMobileCode = true;
    } else {
      clearTimeout(this.COUNT_DOWN_TIMER);
      this.sendMobileCodeCountDown--;
      this.COUNT_DOWN_TIMER = setTimeout(() => {
        this._startCountDown();
      }, 1000);
    }
  }

  /* autorun */
  autoFetchProfile = autorun(() => {
    if (this.isLogin) {
      profileStore.fetchAction("fetchProfile");
      authorityStore.updateAuthorityListAction(this.authorityList);
    } else {
      clearTimeout(this.COUNT_DOWN_TIMER);
      this.canSendMobileCode = true;
      this.sendMobileCodeCountDown = 60;
      profileStore.clearProfileInfoAction();
      authorityStore.clearAuthorityListAction();
    }
  });
}

export default new LoginStore(actionMap, isLoginLS);
