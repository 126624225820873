/** description: 手机验证码登录组件
 *  last_modified: 2019-01-08
 */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Icon, Input, Button, Row, Col } from "antd";
import regExpList from "utils/regExp";

const FormItem = Form.Item;

const STORE = "loginStore";

const LOGIN_ACTION = "login";
const SEND_MOBILE_CODE_ACTION = "sendMobileCode";

@inject(STORE)
@observer
class PhoneForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props[STORE].fetchAction(LOGIN_ACTION, {
          mobile: values.phone,
          vcode: values.vcode,
          type: 1
        });
      }
    });
  };
  handleSendMobileCode = () => {
    this.props.form.validateFields(["phone"], (err, values) => {
      if (!err) {
        this.props[STORE].fetchAction(SEND_MOBILE_CODE_ACTION, {
          mobile: values.phone,
          type: "login"
        });
      }
    });
  };
  render() {
    let mobileCodeBtn;
    const { getFieldDecorator } = this.props.form;
    const {
      canSendMobileCode,
      sendMobileCodeCountDown
    } = this.props.loginStore;
    if (canSendMobileCode) {
      mobileCodeBtn = (
        <Button
          type="default"
          size="large"
          className="login-form-button"
          disabled={this.props[STORE].fetchingStatus[SEND_MOBILE_CODE_ACTION]}
          onClick={this.handleSendMobileCode}
        >
          获取验证码
        </Button>
      );
    } else {
      mobileCodeBtn = (
        <Button
          type="default"
          size="large"
          className="login-form-button"
          disabled={true}
        >
          {sendMobileCodeCountDown}
        </Button>
      );
    }
    return (
      <div>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem>
            {getFieldDecorator("phone", {
              rules: [
                { required: true, message: "请输入您的手机号" },
                { pattern: regExpList.phone, message: "您的手机号码输入错误" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="mobile" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                size="large"
                type="tel"
                length="11"
                placeholder="手机号码"
              />
            )}
          </FormItem>
          <FormItem>
            <Row>
              <Col span={16}>
                {getFieldDecorator("vcode", {
                  rules: [{ required: true, message: "请输入短信验证码" }]
                })(
                  <Input
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    size="large"
                    type="text"
                    placeholder="短信验证码"
                  />
                )}
              </Col>
              <Col span={7} offset={1}>
                {mobileCodeBtn}
              </Col>
            </Row>
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              className="login-form-button"
              disabled={this.props[STORE].fetchingStatus[LOGIN_ACTION]}
              loading={this.props[STORE].fetchingStatus[LOGIN_ACTION]}
              onClick={this.handleSubmit}
            >
              登录
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const LoginPhoneSection = Form.create()(PhoneForm);

export default LoginPhoneSection;
