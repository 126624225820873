const regExpList = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^1\d{10}$/ /* 手机号码 */,
  sample_code: /^\S{14}$/ /* 检测编码 */,
  idcard: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/ /* 身份证（简单校验） */,
  decimal: /^[0-9]+([.]{1}[0-9]{1,2})?$/ /* 最多两位小数 */,
  // password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/ /* 密码至少8位必须包含字母和数字最长20位 */,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~!@#$%^&*_]{8,20}$/, // 必须包含大小写字母和数字的组合，可以使用特殊字符(~!@#$%^&*_)，长度在8-20之间)
  alias: /^[\dA-Za-z_]{1,16}$/ /* 昵称 */
};

export default regExpList;
