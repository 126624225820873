import { action, observable, runInAction } from "mobx";
import {
  fetchAccountChart,
  fetchBusinessStatistics,
  fetchChannelAccount
} from "services/statistic";

import ModelsBase from "utils/models";
import { message } from "antd";

class DateHeader {
  @observable
  date_type = 1; // 1: "今日",2: "本周",3: "本月",4: "全年" 5: 使用 start_time 和 end_time

  @observable
  start_time = "";

  @observable
  end_time = "";

  @action
  setDateType(v) {
    this.date_type = +v;
  }

  @action
  setTimes(startTime, endTime) {
    this.start_time = startTime;
    this.end_time = endTime;
  }

  get data() {
    return {
      date_type: this.date_type,
      start_time: this.start_time,
      end_time: this.end_time
    };
  }
}

class GraphModal {
  @observable
  title = "";

  @observable
  desc = "";

  @observable
  visible = false;

  @observable
  graph = {
    type: "sample_num",
    data: {}
  };

  @action.bound
  visibleToggle() {
    this.visible = !this.visible;

    if (!this.visible) {
      this.initGraphModal();
    }
  }

  @action
  initGraphModal() {
    this.title = "";
    this.visible = false;
    this.graph = {
      type: "sample_num",
      data: {}
    };
  }

  @action.bound
  setData(title, data, desc) {
    this.title = title;
    this.graph.data = data;
    this.desc = desc;
  }

  @action.bound
  setType(type) {
    this.graph.type = type;
  }
}

class StatisticsStore extends ModelsBase {
  dateHeader = new DateHeader();

  graphModal = new GraphModal();

  /** 整体概览数据 */
  @observable
  tableOverview = {};

  @action.bound
  fetchAccountChartAction = async () => {
    try {
      const { start_time, end_time, date_type } = this.dateHeader;
      const res = await fetchAccountChart({
        start_time,
        end_time,
        date_type
      });

      if (res.code === 1) {
        this.tableOverview = res.data;
      } else {
        message.info(res.message);
      }
    } catch (error) {
      this.tableOverview = {};
      message.error(error.message);
      console.log(error);
    }
  };

  @observable
  business = {
    data: [],
    isLoading: false
  };

  @action.bound
  fetchBusinessStatisticsAction = async () => {
    this.business.isLoading = true;
    try {
      const o = this.dateHeader.data;
      const res = await fetchBusinessStatistics(o);
      if (res.code === 1) {
        this.business.data = res.data;
      } else {
        message.info(res.message);
      }
      this.business.isLoading = false;
    } catch (error) {
      this.business.data = [];
      this.business.isLoading = false;
      message.error(error.message);
      throw error;
    }
  };

  @observable
  table = {
    data: [],
    total: 0,
    isLoading: false,
    query: {
      team_id: "",
      contact_id: "",
      business_id: "",
      channel_name: "",
      cur_page: "",
      page_size: 50
    },
    serach_contact_list: [],
    serach_business_list: [],
    serach_team_list: [],
    total_sample_num: 0,
    total_trans_amount: 0,
    total_trans_num: 0
  };

  @action.bound
  fetchChannelAccountAction = async () => {
    this.table.isLoading = true;
    try {
      const res = await fetchChannelAccount({
        ...this.dateHeader.data,
        ...this.table.query
      });
      if (res.code === 1) {
        runInAction(() => {
          this.table.data = res.data.items;
          this.table.total = res.data.total;
          this.table.serach_business_list = res.data.serach_business_list || [];
          this.table.serach_contact_list = res.data.serach_contact_list || [];
          this.table.serach_team_list = res.data.serach_team_list || [];

          this.table.total_sample_num = res.data.total_sample_num;
          this.table.total_trans_amount = res.data.total_trans_amount;
          this.table.total_trans_num = res.data.total_trans_num;
        });
      } else {
        message.info(res.message);
        this.table.data = [];
      }
      this.table.isLoading = false;
    } catch (error) {
      this.table.data = [];
      this.table.isLoading = false;
      message.error(error.message);
    }
  };

  @action.bound
  setCurrentPage = page => {
    this.table.query.cur_page = page;
  };

  @action.bound
  setPageSize = n => {
    this.table.query.page_size = n;
  };

  @action.bound
  setTableSearch = o => {
    this.table.query.contact_id = (o && o.contact_id) || "";
    this.table.query.business_id = (o && o.business_id) || "";
    this.table.query.team_id = (o && o.team_id) || "";
    this.table.query.channel_name = (o && o.channel_name) || "";
  };
}

export default new StatisticsStore();
