import request from "utils/request";

/* 获取用户列表 */
export async function fetchUserList(obj) {
  return request("/api/user/member_list", {
    method: "GET",
    data: obj
  });
}

/* 新增用户 */
export async function addUser(obj) {
  return request("/api/user/add_member", {
    method: "POST",
    data: obj
  });
}

/* 编辑用户信息 */
export async function editUser(obj) {
  return request("/api/user/edit_member", {
    method: "POST",
    data: obj
  });
}

/* 获取团队列表 */
export async function fetchTeamList(obj) {
  return request("/api/user/team_list", {
    method: "GET",
    data: obj
  });
}

/* 编辑/新增团队 */
export async function addTeam(obj) {
  return request("/api/user/handle_team", {
    method: "POST",
    data: obj
  });
}

/* 修改团队状态 */
export async function editTeamStatus(obj) {
  return request("/api/user/set_team_status", {
    method: "POST",
    data: obj
  });
}

/* 修改团队状态 */
export async function editUserStatus(obj) {
  return request("/api/user/set_status", {
    method: "POST",
    data: obj
  });
}
