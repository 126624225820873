import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Radio, Modal, Icon } from "antd";

const RadioGroup = Radio.Group;

const LOGIN_STORE = "loginStore";
const PROFILE_STORE = "profileStore";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px"
};

@inject(LOGIN_STORE)
@inject(PROFILE_STORE)
@observer
class RoleModal extends Component {
  handleCancel = () => {
    this.props[PROFILE_STORE].handleToggleModalShow();
  };
  handleSave = () => {
    this.props[PROFILE_STORE].fetchAction("changeAccount", {
      change_account_id: this.props[PROFILE_STORE].current_account,
      mobile: this.props[PROFILE_STORE].profile.mobile
    }).then(res => {
      if (res.code === 1) {
        // 切换成功之后刷新页面
        window.location.href = "/";
      }
    });
    this.handleCancel();
  };
  handleChange = e => {
    this.props[PROFILE_STORE].handleChangeValue(e.target.value);
  };
  render() {
    const title = (
      <div>
        <Icon
          type="info-circle"
          theme="twoTone"
          style={{ marginRight: "10px" }}
        />
        <span>切换账户</span>
      </div>
    );
    const current_account = this.props[
      PROFILE_STORE
    ].current_account.toString(); // 当前账户id

    let accountList = this.props[PROFILE_STORE].profile.account_list;

    return (
      <Modal
        title={title}
        visible={this.props[PROFILE_STORE].isModalShow}
        onOk={this.handleSave}
        onCancel={this.handleCancel}
      >
        <h4 style={{ fontSize: 14 }}>请选择要登录的账户：</h4>
        <RadioGroup onChange={this.handleChange} value={current_account}>
          {accountList.map(item => {
            const _padding = item => {
              if (
                item.id === this.props[PROFILE_STORE].profile.present_account_id
              ) {
                return item.account_mesg === ""
                  ? " - 当前登录账户"
                  : `(${item.account_mesg}) - 当前登录账户`;
              } else {
                return item.account_mesg === "" ? "" : `(${item.account_mesg})`;
              }
            };

            return (
              <Radio style={radioStyle} value={item.id} key={item.id}>
                {`${item.account_name}${_padding(item)}`}
              </Radio>
            );
          })}
        </RadioGroup>
      </Modal>
    );
  }
}

export default RoleModal;
