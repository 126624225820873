import request from "utils/request";

/* 获取qpcr报告 */
export async function fetchReport(obj) {
  return request("/api/sample/get_report_info", {
    method: "GET",
    data: {
      id: obj.id,
      preview: obj.preview
    }
  });
}
