import { observable, action, runInAction } from "mobx";
import { message } from "antd";

import {
  fetchAccountList,
  fetchAccountLoginList,
  addLoginAccount,
  fetchAccountDetail,
  deleteLoginDel,
  editAccountDetail
} from "services/account_manage";

class AccountManageStore {
  @observable
  table = {
    data: [],
    total: 0,
    isLoading: false,
    account_name_list: [],
    status_list: {},
    query: {
      end_time: "",
      start_time: "",
      account_name_list_id: "",
      status: "",
      cur_page: "",
      page_size: 10
    }
  };

  @action
  setTableSearch(o) {
    this.table.query.start_time = o.start_time;
    this.table.query.end_time = o.end_time;
    this.table.query.account_name_list_id = o.account_name_list_id;
    this.table.query.status = o.status;
  }

  @action.bound
  fetchAccountListAction = async () => {
    this.table.isLoading = true;
    try {
      const res = await fetchAccountList(this.table.query);
      this.table.isLoading = false;

      if (res.code === 1) {
        const { items, total, account_name_list, status_list } = res.data;

        runInAction(() => {
          this.table.total = total;
          this.table.account_name_list = account_name_list;
          this.table.status_list = status_list;

          if (Array.isArray(items)) {
            this.table.data = items;
          } else {
            this.table.data = [];
          }
        });
      } else {
        message.info(res.message);
      }
    } catch (error) {
      message.error(error.message);
      this.table.isLoading = false;
    }
  };

  @action.bound
  setCurrentPage = page => {
    this.table.query.cur_page = page;
  };

  @action.bound
  resetFormFields = () => {
    this.table.query.end_time = "";
    this.table.query.start_time = "";
    this.table.query.account_name_list_id = "";
    this.table.query.status = "";
    this.setCurrentPage(1);
  };

  @action.bound
  setTableQueryMobile = mobile => {
    this.table.query.mobile = mobile;
  };

  @action.bound
  setTableQueryNumberName = numberName => {
    this.table.query.number_name = numberName;
  };

  // ModalPhoneList 状态，查看手机号
  @observable
  modalPhone = {
    isVisiable: false,
    data: []
  };

  @action
  toggleModalPhoneVisiable = () => {
    this.modalPhone.isVisiable = !this.modalPhone.isVisiable;
  };

  @action.bound
  fetchAccountLoginListAction = async account_id => {
    try {
      const data = await fetchAccountLoginList({ account_id });

      if (data.code === 1) {
        runInAction(() => {
          this.modalPhone.data = data.data;
          this.toggleModalPhoneVisiable();
        });
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  // 添加登录账号的状态
  @observable
  modalAddLogin = {
    isVisiable: false,
    account_name: "",
    account_mesg: "",
    form: {
      account_id: "",
      mobile: "",
      submit_token: ""
    }
  };

  @action
  toggleModalAddLoginVisiable = () => {
    this.modalAddLogin.isVisiable = !this.modalAddLogin.isVisiable;
  };

  @action
  setModalAddLoginState = ({ account_mesg, account_name, account_id }) => {
    this.modalAddLogin.form.account_id = account_id;
    this.modalAddLogin.account_name = account_name;
    this.modalAddLogin.account_mesg = account_mesg;
  };
  @action
  setModalAddLoginFormMobile = mobile => {
    this.modalAddLogin.form.mobile = mobile;
  };
  @action
  resetModalAddLoginForm = () => {
    this.modalAddLogin.form.account_id = "";
    this.modalAddLogin.form.mobile = "";
    this.modalAddLogin.form.submit_token = "";
  };

  @action.bound
  addLoginAccountAction = async ({ submit_token }) => {
    try {
      const res = await addLoginAccount({
        ...this.modalAddLogin.form,
        submit_token
      });

      if (res.code === 1) {
        message.success(res.data.mesg);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  // 账户详情
  @observable
  accountDetail = {
    isLoading: false,
    account_team_name: "无",
    info: {
      id: "",
      status: "",
      account_name: "",
      account_mesg: "",
      create_time: "",
      type: ""
    },
    loginList: [],
    teamList: [],
    business_list: [],
    business_info: {
      id: ""
    }
  };

  @action
  resetAccountDetailInfo = () => {
    this.accountDetail.account_team_name = "";
    this.accountDetail.info.id = "";
    this.accountDetail.info.status = "";
    this.accountDetail.info.account_name = "";
    this.accountDetail.info.account_mesg = "";
    this.accountDetail.info.create_time = "";
    this.accountDetail.info.type = "";
    this.accountDetail.loginList = [];
    this.accountDetail.teamList = [];
  };

  @action.bound
  fetchAccountDetailAction = async account_id => {
    try {
      this.accountDetail.isLoading = true;
      const res = await fetchAccountDetail({ account_id });

      this.accountDetail.isLoading = false;
      if (res.code === 1) {
        runInAction(() => {
          this.accountDetail.account_team_name = res.data.account_team_name;
          this.accountDetail.info = res.data.info;
          this.accountDetail.loginList = res.data.login_list;
          this.accountDetail.teamList = res.data.team_list;
          this.accountDetail.business_list = res.data.business_list;
          this.accountDetail.business_info = res.data.business_info;
        });
      } else {
        message.info(res.message);
      }
    } catch (error) {
      this.accountDetail.isLoading = false;
      message.error(error.message);
      throw error;
    }
  };

  @action
  setAccountDetailInfoStatus = status => {
    this.accountDetail.info.status = status;
  };

  @action
  setAccountDetailBusinessInfoId = id => {
    this.accountDetail.business_info.id = id;
  };

  @action.bound
  deleteLoginDelAction = async ({ account_id, id }) => {
    try {
      const res = await deleteLoginDel({ account_id, login_id: id });

      if (res.code === 1) {
        message.success("删除成功");

        runInAction(() => {
          const list = this.accountDetail.loginList.filter(i => i.id !== id);
          this.accountDetail.loginList = list;
        });
      } else {
        message.info(res.message);
      }
    } catch (error) {
      message.error(error.message);
      throw error;
    }
  };

  @action.bound
  editAccountDetailAction = async () => {
    try {
      const res = await editAccountDetail({
        submit_token: new Date().getTime(),
        account_id: this.accountDetail.info.id,
        status: this.accountDetail.info.status,
        change_account_id: this.accountDetail.business_info.id
      });
      if (res.code === 1) {
        message.success(res.data.mesg);
      } else {
        message.error(res.message);
      }
      return res.code === 1;
    } catch (error) {
      message.error(error.message);
      throw error;
    }
  };
}

export default new AccountManageStore();
