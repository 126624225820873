import React, { Component } from "react";
import { Provider } from "mobx-react";
import stores from "./models";
import Authority from "./routes/authority";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");

class App extends Component {
  render() {
    return (
      <Provider {...stores}>
        <Authority />
      </Provider>
    );
  }
}

export default App;
