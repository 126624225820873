import request from "utils/request";

/* 获取医院列表 */
export async function fetchHospitalList(obj) {
  return request("/api/hospital", {
    method: "GET",
    data: obj
  });
}

/* 获取科室列表 */
export async function fetchDepartmentList(hospital_id) {
  return request("/api/hospital/department_list", {
    method: "GET",
    data: {
      hospital_id: hospital_id
    }
  });
}

/* 编辑或新建医院 */
export async function editHospital(obj) {
  return request("/api/hospital/handle_hospital", {
    method: "POST",
    data: obj
  });
}

/* 编辑或新建科室 */
export async function editDepartment(obj) {
  return request("/api/hospital/handle_department", {
    method: "POST",
    data: obj
  });
}

/* 删除医院 */
export async function editHospitalStatus(obj) {
  return request("/api/hospital/set_hospital_status", {
    method: "POST",
    data: obj
  });
}

/* 删除科室 */
export async function deleteDepartment(obj) {
  return request("/api/hospital/set_department_status", {
    method: "POST",
    data: obj
  });
}
