import React, { Component } from "react";
import { Redirect } from "react-router";
import { observer, inject } from "mobx-react";
import UserLayout from "layouts/UserLayout";
import styles from "./Login.module.css";
import LoginSection from "components/LoginSection";

@inject("loginStore")
@observer
class Login extends Component {
  render() {
    if (this.props.loginStore.isLogin) {
      return <Redirect to="/" />;
    }
    return (
      <UserLayout>
        <div className={styles.login_section}>
          <img
            src={`${process.env.PUBLIC_URL}/img/logo_login.svg?v=3`}
            alt="logo"
            className={styles.logo}
          />
          <LoginSection />
        </div>
      </UserLayout>
    );
  }
}

export default Login;
