/* 基础页面 */
const BaseRoutes = [
  {
    name: "项目概览",
    icon: "dashboard",
    path: "/",
    type: "menu",
    need_authority: false,
    exact: true
  },
  {
    name: "登录",
    path: "/login",
    type: "system"
  },
  {
    name: "报告查看",
    path: "/report",
    type: "system"
  },
  {
    name: "16s报告查看",
    path: "/report_v2",
    type: "system"
  },
  {
    name: "16s第三版报告查看",
    path: "/report_v3",
    type: "system"
  },
  {
    name: "qpcr报告查看",
    path: "/qpcr_report",
    type: "system"
  },
  {
    name: "短信推送报告",
    path: "/message_report",
    type: "system"
  },
  {
    name: "qpcr_v2报告查看",
    path: "/qpcr_report_v2",
    type: "system"
  },
  {
    name: "池根亿qpcr报告查看",
    path: "/report_qpcr_chigenyi",
    type: "system"
  },
  {
    name: "美素佳儿报告查看",
    path: "/report_friso",
    type: "system"
  },
  {
    name: "量子报告查看",
    path: "/report_quantum",
    type: "system"
  },
  {
    name: "子宫内膜炎病原体检测报告查看",
    path: "/report_pathogen",
    type: "system"
  },
  {
    name: "泌尿生殖道感染原体检测报告查看",
    path: "/report_urogenital",
    type: "system"
  },
  {
    name: "新型冠状病毒检测报告查看",
    path: "/report_ncov",
    type: "system"
  },
  {
    name: "工具页面",
    path: "/tools",
    type: "system"
  },
  {
    name: "403",
    path: "/exception/403",
    type: "system"
  },
  {
    name: "500",
    path: "/exception/500",
    type: "system"
  },
  {
    name: "404",
    path: "",
    type: "system",
    need_authority: false
  }
];

export default BaseRoutes;
