import { observable, action, computed } from "mobx";
import dayjs from "dayjs";
import { message } from "antd";
import {
  fetchProfileInfo,
  editProfileName,
  editProfileMobile,
  checkMobile,
  changeAccount
} from "services/profile";
import LocalStorage from "utils/localstorage";
import ModelsBase from "utils/models";

/* 本地存储用户相关信息 */
const PROFILE_MAX_AGE = +process.env.REACT_APP_LS_CACHE_DAYS; // 单位 : 天
const PROFILE_LS_KEY = "profile";

const profileLS = LocalStorage.getData(PROFILE_LS_KEY);

const ADMIN_ROLE = 1;

const actionMap = {
  fetchProfile: {
    api: fetchProfileInfo,
    dataFunction: (_this, key, data) => {
      _this.profile = data;
      _this.current_account = data.present_account_id;

      LocalStorage.setData(
        PROFILE_LS_KEY,
        data,
        dayjs().add(PROFILE_MAX_AGE, "day")
      );
    }
  },
  checkMobile: {
    api: checkMobile,
    type: "post",
    dataFunction: () => {}
  },
  updateProfileName: {
    api: editProfileName,
    type: "post",
    dataFunction: () => {
      message.success("更新成功");
    },
    errorMessage: "编辑用户姓名失败"
  },
  updateProfileMobile: {
    api: editProfileMobile,
    type: "post",
    dataFunction: () => {
      message.success("更新成功");
    },
    errorMessage: "修改用户手机号失败"
  },
  changeAccount: {
    api: changeAccount,
    type: "post",
    dataFunction: () => {
      message.success("切换账户成功");
    },
    errorMessage: "切换账户失败"
  }
};

class ProfileStore extends ModelsBase {
  constructor(actionMap, profile) {
    super(actionMap);
    this.profile = profile;
  }

  @observable
  isModalShow = false;

  @observable
  profile = {};

  /** 当前账户 */
  @observable
  current_account = "";

  /* 处理头像 */
  @computed
  get profileAvatar() {
    return this.profile.name ? this.profile.name.substr(0, 1) : "";
  }

  @computed
  get profileHello() {
    const now = dayjs(new Date());
    const hour = now.hour();
    const name = this.profile.name || "";
    let text = "";
    if (hour > 5 && hour <= 11) {
      text = `早安，${name}老师，祝您开心每一天。`;
    } else if (hour > 11 && hour <= 14) {
      text = `中午好，${name}老师，喝杯茶休息一下。`;
    } else if (hour > 14 && hour < 18) {
      text = `下午好，${name}老师，工作的同时也要休息下眼睛。`;
    } else if (hour >= 18 || hour <= 5) {
      text = `晚上好，${name}老师，早些休息，迎接美好的一天。`;
    }
    return text;
  }

  /* 判断是否是管理员 */
  @computed
  get isAdminUser() {
    return +this.profile.role === ADMIN_ROLE;
  }

  /* 清除当前登录用户信息 */
  @action
  clearProfileInfoAction() {
    this.profile = {};
    LocalStorage.clearData(PROFILE_LS_KEY);
  }

  @action
  handleChangeValue(id) {
    this.current_account = id;
  }

  /** 切换账户弹窗是否可见 */
  @action
  handleToggleModalShow() {
    this.isModalShow = !this.isModalShow;
  }

  @action
  isCurProfile(id) {
    return +this.profile.id === +id;
  }
}

export default new ProfileStore(actionMap, profileLS);
