import React, { Suspense } from "react";
import CommonPageLoading from "components/CommonPageLoading";

import DashBoard from "pages/dashboard";
import Login from "pages/Login";
import Exception from "pages/Exception";
const MessageReport = React.lazy(() => import("pages/message_report/index"));
const Report = React.lazy(() => import("pages/report/index"));
const ReportV2 = React.lazy(() => import("pages/report_v2/index"));
const ReportV3 = React.lazy(() => import("pages/report_v3/index"));
const QpcrReport = React.lazy(() => import("pages/qpcr_report/index"));
const V2QpcrReport = React.lazy(() => import("pages/qpcr_report_v2/index"));
const FrisoReport = React.lazy(() => import("pages/report_friso/index"));
const PathogenReport = React.lazy(() => import("pages/report_pathogen/index"));
const UrogenitalReport = React.lazy(() =>
  import("pages/report_urogenital/index")
);
const UnovReport = React.lazy(() => import("pages/report_ncov/index"));
const QuantumReport = React.lazy(() => import("pages/report_quantum/index"));
const Tools = React.lazy(() => import("pages/tools/index"));
const ReportQpcrChigenyi = React.lazy(() =>
  import("pages/reports/report_qpcr_chigenyi/index")
);

const BaseComponents = [
  {
    path: "/",
    component: props => {
      return <DashBoard />;
    }
  },
  {
    path: "/login",
    component: props => {
      return <Login />;
    }
  },
  {
    path: "/report",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <Report />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/report_v2",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <ReportV2 />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/report_v3",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <ReportV3 />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/qpcr_report",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <QpcrReport />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/report_friso",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <FrisoReport />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/report_quantum",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <QuantumReport />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/qpcr_report_v2",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <V2QpcrReport />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/report_qpcr_chigenyi",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <ReportQpcrChigenyi />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/report_pathogen",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <PathogenReport />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/report_urogenital",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <UrogenitalReport />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/report_ncov",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <UnovReport />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/message_report",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <MessageReport />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/tools",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <Tools />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/exception/403",
    component: props => {
      return <Exception title="403" tips="抱歉，你无权访问该页面" />;
    }
  },
  {
    path: "/exception/500",
    component: props => {
      return <Exception title="500" tips="抱歉，服务器出错了" />;
    }
  },
  {
    path: "",
    component: props => {
      return <Exception title="404" tips="抱歉，你访问的页面不存在" />;
    }
  }
];

export default BaseComponents;
