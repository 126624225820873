//import { message } from "antd";
import LocalStorage from "utils/localstorage";
import QS from "qs";
import axios from "axios";

const STATUS_ERROR_MAP = {
  403: "无权访问，请联系管理员",
  404: "不存在的页面，请联系管理员",
  500: "服务器错误，请稍后重试"
};

const CODE_ERROR_MAP = {
  "1008": "请重新的登录",
  "1009": "请重新设置密码"
};

export default function request(url, options) {
  return new Promise((resolve, reject) => {
    let config = {};
    if (options.method.toUpperCase() === "GET") {
      config = {
        method: "get",
        params: options.data
      };
    } else {
      config = {
        method: "post",
        data: QS.stringify(options.data)
      };
    }
    axios.interceptors.request.use(config => {
      config.headers["Cache-Control"] = `no-cache`;
      return config;
    });
    axios(
      Object.assign({}, config, {
        url: url
      })
    )
      .then(res => {
        const data = res.data;
        if (data.code === 1) {
          resolve(data);
        } else {
          return Promise.reject(data);
        }
      })
      .catch(err => {
        const data = err.response;
        const status = err.status;
        const code = err.code;
        if (data) {
          reject({
            code: data.code,
            message: data.data.message
          });
        } else if (code !== undefined) {
          if (code === 1008) {
            LocalStorage.clearAll();
            window.location.href = "/login";
          } else if (code === 1009) {
            LocalStorage.clearAll();
            window.location.href = "/login/reset";
          } else {
            const msg = CODE_ERROR_MAP[code]
              ? CODE_ERROR_MAP[code]
              : err.message;
            //message.error(msg);
            reject({
              code: code,
              message: msg
            });
          }
        } else {
          const msg = STATUS_ERROR_MAP[status]
            ? STATUS_ERROR_MAP[status]
            : status + " 网络错误，请稍后重试";
          reject({
            code: -500,
            message: msg
          });
        }
      });
  });
}
