import React, { Suspense } from "react";

import CommonPageLoading from "components/CommonPageLoading";

const Statistics = React.lazy(() => import("pages/statistics/index"));

const MemberList = React.lazy(() => import("pages/user/member/index"));
const TeamList = React.lazy(() => import("pages/user/team/index"));
const AccountSetting = React.lazy(() => import("pages/account/setting/index"));

const MemberManage = React.lazy(() => import("pages/user/member_manage/index"));

const HospitalSetting = React.lazy(() =>
  import("pages/setting/hospital/list/index")
);
const HospitalAdd = React.lazy(() =>
  import("pages/setting/hospital/add/index")
);
const AccountManage = React.lazy(() =>
  import("pages/setting/account_manage/list")
);
const AccountDetail = React.lazy(() =>
  import("pages/setting/account_manage/detail")
);

const SampleList = React.lazy(() => import("pages/sample/list/index"));
const SampleDetail = React.lazy(() => import("pages/sample/detail/index"));

const Channel = React.lazy(() => import("pages/cooperate/channel/list/index"));
const ChannelAdd = React.lazy(() =>
  import("pages/cooperate/channel/add/index")
);
const GoodAdd = React.lazy(() => import("pages/cooperate/channel/add/goodAdd"));
const ActivityAdd = React.lazy(() =>
  import("pages/cooperate/channel/add/activityAdd")
);
const HelpManage = React.lazy(() => import("pages/help/index"));

const Coupon = React.lazy(() => import("pages/coupon/index"));

/* 业务页面 */
const PageComponents = [
  {
    path: "/statistics",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <Statistics />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/sample",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <SampleList />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/sample/detail",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <SampleDetail />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/user",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <MemberManage />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/user/member",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <MemberList />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/user/team",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <TeamList />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/cooperate/channel",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <Channel />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/cooperate/channel_add",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <ChannelAdd />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/cooperate/good_add",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <GoodAdd />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/cooperate/activity_add",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <ActivityAdd />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/setting/hospital",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <HospitalSetting />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/setting/hospital_add",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <HospitalAdd />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/setting/account",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <AccountManage />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/setting/account_detail",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <AccountDetail />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/account/setting",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <AccountSetting />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/help",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <HelpManage />
          </Suspense>
        </div>
      );
    }
  },
  {
    path: "/coupon",
    component: () => {
      return (
        <div>
          <Suspense fallback={<CommonPageLoading />}>
            <Coupon />
          </Suspense>
        </div>
      );
    }
  }
];

export default PageComponents;
