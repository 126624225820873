import React, { Component } from "react";
import styles from "./styles.module.css";

class PageTitle extends Component {
  render() {
    return (
      <div className={styles.section}>
        <div className={styles.title}>{this.props.title}</div>
        {this.props.children}
      </div>
    );
  }
}

export default PageTitle;
