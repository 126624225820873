import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { Menu, Icon, Layout } from "antd";
import styles from "./styles.module.css";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

@inject("uiStore")
@inject("routerStore")
@observer
class CommonMenu extends Component {
  getMenuItems = list => {
    return list.map(i => {
      const icon = i.icon ? <Icon type={i.icon} theme="outlined" /> : "";
      return (
        <Menu.Item key={i.path}>
          <Link to={i.path}>
            {icon}
            <span>{i.name}</span>
          </Link>
        </Menu.Item>
      );
    });
  };
  render() {
    const logo = this.props.uiStore.slideOpen
      ? `${process.env.PUBLIC_URL}/img/logo_s.svg?v=2`
      : `${process.env.PUBLIC_URL}/img/logo_l.svg?v=3`;
    const MenuItemList = this.props.uiStore.menuList.map(i => {
      if (i.routes !== undefined && i.routes.length > 0) {
        return (
          <SubMenu
            key={i.path}
            title={
              <span>
                <Icon type={i.icon} theme="outlined" />
                <span>{i.name}</span>
              </span>
            }
          >
            {this.getMenuItems(i.routes)}
          </SubMenu>
        );
      } else {
        const icon = i.icon ? <Icon type={i.icon} theme="outlined" /> : "";
        return (
          <Menu.Item key={i.path}>
            <Link to={i.path}>
              {icon}
              <span>{i.name}</span>
            </Link>
          </Menu.Item>
        );
      }
    });
    return (
      <Sider
        breakpoint="lg"
        defaultCollapsed="false"
        collapsed={this.props.uiStore.slideOpen}
        onBreakpoint={broken => {
          this.props.uiStore.hanldeSlideBreakpointAction(broken);
        }}
      >
        <div>
          <div className={styles.logo}>
            <img src={logo} alt="logo" className={styles.img} />
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={[this.props.routerStore.location.pathname]}
            mode="inline"
            className={styles.menu}
            defaultOpenKeys={[
              "/" + this.props.routerStore.location.pathname.split("/")[1]
            ]}
          >
            {MenuItemList}
          </Menu>
        </div>
      </Sider>
    );
  }
}

export default CommonMenu;
