import request from "utils/request";

export async function fetchCouponList(obj) {
  return request("/api/coupon", {
    method: "GET",
    data: obj
  });
}

// export async function uploadExcel(obj) {
//   return request("/api/coupon/import_coupons_info", {
//     method: "POST",
//     data: obj
//   });
// }

// 美素佳儿数据统计
export async function fetchCouponStatistics(obj) {
  return request("/api/coupon/friso_data_statistics", {
    method: "GET",
    data: obj
  });
}
