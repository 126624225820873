import request from "utils/request";

/* 获取合作方列表 */
export async function fetchCooperationList(obj) {
  return request("/api/user/cooperation_list", {
    method: "GET",
    data: obj
  });
}

/* 修改合作方状态 */
export async function editCooperationStatus(obj) {
  return request("/api/user/set_status", {
    method: "POST",
    data: {
      id: obj.id,
      status: obj.status
    }
  });
}

/* 新增合作方 */
export async function addCooperation(obj) {
  return request("/api/user/add_cooperation", {
    method: "POST",
    data: obj
  });
}

/* 修改合作方信息 */
export async function editCooperation(obj) {
  return request("/api/user/edit_cooperation", {
    method: "POST",
    data: obj
  });
}

/* 获取用户角色列表 */
export async function fetchUserRoleList() {
  return request("/api/user/get_role_data", {
    method: "GET"
  });
}

/* 获取科室列表 */
export async function fetchDepartmentList(hospital_id) {
  return request("/api/hospital/department_list", {
    method: "GET",
    data: {
      hospital_id: hospital_id
    }
  });
}

/* 删除该渠道 */
export async function deleteCooperation(obj) {
  return request("/api/user/cooperation_del", {
    method: "POST",
    data: obj
  });
}

/* 获取该渠道详情 */
export async function detailCooperation(obj) {
  return request("/api/user/cooperation_list", {
    method: "GET",
    data: obj
  });
}

/* 获取新增商品页面的商品模版列表 */
export async function fetchTemplateList(obj) {
  return request("/api/user/goods_module_list_for_fe", {
    method: "GET",
    data: obj
  });
}

/* 获取新增商品页面的下拉商品列表 */
export async function fetchGoodList(obj) {
  return request("/api/user/goods_list", {
    method: "GET",
    data: obj
  });
}

/* 获取新增商品页面的商品详情 */
export async function fetchGoodDetail(obj) {
  return request("/api/user/goods_detail", {
    method: "GET",
    data: obj
  });
}

/* 获取新增商品页面的包装列表 */
export async function fetchGoodPackage(obj) {
  return request("/api/user/package_list", {
    method: "GET",
    data: obj
  });
}

/* 新增商品 */
export async function addGood(obj) {
  return request("/api/user/goods_add", {
    method: "POST",
    data: obj
  });
}

/* 编辑商品 */
export async function editGood(obj) {
  return request("/api/user/goods_edit", {
    method: "POST",
    data: obj
  });
}

/* 渠道商品列表 */
export async function fetchChannelGoodList(obj) {
  return request("/api/user/canal_goods_list", {
    method: "GET",
    data: obj
  });
}

/* 该渠道的tab值 */
export async function fetchChannelTab(obj) {
  return request("/api/user/canal_tab", {
    method: "GET",
    data: obj
  });
}

/* 获取某个渠道的商品渠道详情 */
export async function fetchGoodChannelDetail(obj) {
  return request("/api/user/canal_goods_detail", {
    method: "GET",
    data: obj
  });
}

/* 活动配置相关接口 */

// 1、活动类型为试用类的试用商品列表
export async function getSyProductList(obj) {
  return request("/api/user/get_products_list_sy", {
    method: "GET",
    data: obj
  });
}

// 2、活动类型为买赠类的指定商品列表
export async function getMzZdProductList(obj) {
  return request("/api/user/assign_product_list_mz", {
    method: "GET",
    data: obj
  });
}

// 3、活动类型为买赠类的，赠送信息处的商品列表
export async function getMzProductList(obj) {
  return request("/api/user/get_products_list_mz", {
    method: "GET",
    data: obj
  });
}

// 4、新增活动配置
export async function addChannelActivity(obj) {
  return request("/api/user/channel_activity_add", {
    method: "POST",
    data: obj
  });
}

// 5、活动商品 模板列表
export async function getActivityModuleList(obj) {
  return request("/api/user/activity_module_list", {
    method: "GET",
    data: obj
  });
}

// 试用类商品获取模板列表
export async function getActivitySyModuleList(obj) {
  return request("/api/user/activity_module_list_sy", {
    method: "GET",
    data: obj
  });
}

// 6、渠道活动列表
export async function getChannelActivityList(obj) {
  return request("/api/user/channel_activity_list", {
    method: "GET",
    data: obj
  });
}

// 7、渠道活动详情页
export async function getActivityDetail(obj) {
  return request("/api/user/channel_activity_detail", {
    method: "GET",
    data: obj
  });
}

// 9、编辑配置活动商品
export async function editChannelActivity(obj) {
  return request("/api/user/channel_activity_edit", {
    method: "POST",
    data: obj
  });
}

// v2.3.2新增医院
export async function addHospital(obj) {
  return request("/api/hospital/add_hospital_by_name", {
    method: "POST",
    data: obj
  });
}

// 新增医院科室
export async function addDepartment(obj) {
  return request("/api/hospital/add_department", {
    method: "POST",
    data: obj
  });
}

// 擅长关注的接口
export async function getReportFocusList(obj) {
  return request("/api/report/focus_list", {
    method: "GET",
    data: obj
  });
}
