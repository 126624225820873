import request from "utils/request";

/* 获取当前登录用户信息 */
export async function fetchProfileInfo() {
  return request("/api/user", {
    method: "POST"
  });
}

/* 获取授权信息 */
export async function fetchAuthorityList() {
  return request("/api/user/get_access_list", {
    method: "GET"
  });
}

/* 修改当前登录用户用户名 */
export async function editProfileName(name) {
  return request("/api/user/modify_name", {
    method: "POST",
    data: {
      name: name
    }
  });
}

/* 修改当前登录用户手机号码 */
export async function editProfileMobile(obj) {
  return request("/api/user/modify_mobile", {
    method: "POST",
    data: {
      mobile: obj.mobile,
      vcode: obj.vcode
    }
  });
}

/** 检查手机号是否存在 */
export async function checkMobile(obj) {
  return request("/api/user/check_mobile", {
    method: "POST",
    data: obj
  });
}

/** 切换账户 */
export async function changeAccount(obj) {
  return request("/api/accounts/change_account", {
    method: "POST",
    data: obj
  });
}
