import { observable, action } from "mobx";
import baseRouter from "routes/base";

const _pick = obj => {
  return {
    path: obj.path,
    name: obj.name,
    exact: obj.exact
  };
};

const base = baseRouter.map(i => _pick(i));

class RouterStore {
  @observable
  location = {};

  @observable
  match = {};

  @observable
  history = {};

  @observable
  routerList = base;

  /* 设置路由 */
  @action
  setRoute(location, match, history) {
    this.location = location;
    this.match = match;
    this.history = history;
  }

  /* 更新所有有效的路由表 */
  @action
  updateRouterListAction(list) {
    this.routerList = list.map(i => _pick(i)).concat(base);
  }
}

export default new RouterStore();
