import { observable, action } from "mobx";
import { message } from "antd";
import {
  fetchHospitalList,
  fetchDepartmentList,
  editHospital,
  editDepartment,
  editHospitalStatus,
  deleteDepartment
} from "services/hospital";
import ModelsBase from "utils/models";

const actionMap = {
  hospitalList: {
    api: fetchHospitalList,
    action: "list",
    dataFunction: (_this, key, data) => {
      _this.hospitalIdList = data.hospital_data.items.map(item => {
        _this.new_departmentList[item.id] = item.department_list;
        return [item.id, item.department_list];
      });
      _this.listData[key] = data.hospital_data;
      _this.hospitalStatusList = data.status_list;
      _this.hospitalLists = data.hospital_list;
      _this.typeList = data.type_list;
    },
    errorMessage: "获取合作机构列表失败"
  },
  departmentList: {
    api: fetchDepartmentList,
    dataFunction: (_this, key, data) => {
      _this.departmentList = data;
    },
    errorMessage: "获取科室列表失败"
  },
  editHospitalStatusAction: {
    api: editHospitalStatus,
    method: "post",
    dataFunction: () => {
      message.success("操作成功");
    },
    errorMessage: "删除合作机构失败"
  },
  deleteDepartmentAction: {
    api: deleteDepartment,
    method: "post",
    dataFunction: () => {
      message.success("操作成功");
    },
    errorMessage: "编辑科室状态失败"
  },
  editHospitalAction: {
    api: editHospital,
    method: "post",
    dataFunction: () => {
      message.success("操作成功");
    },
    errorMessage: "新增合作机构失败"
  },
  editDepartmentAction: {
    api: editDepartment,
    method: "post",
    dataFunction: () => {
      message.success("操作成功");
    },
    errorMessage: "编辑科室失败"
  }
};

class HospitalStore extends ModelsBase {
  @observable
  hospitalStatusList = {};

  @observable
  departmentList = [];

  @observable
  new_departmentList = {};

  @observable
  typeList = {};

  @observable
  hospitalEditForm = {};

  /** 合作机构名称列表 */
  @observable
  hospitalLists = {};

  /** 合作机构iD */
  @observable
  hospitalId = 0;

  @observable
  departmentEditForm = {};

  @observable
  drawerVisible = false;

  // 科室弹窗是否可见
  @observable
  modalVisible = false;

  /* 新增编辑合作机构表单 */
  @action
  updateHospitalEditFormAction(obj) {
    this.hospitalEditForm = obj;
    this.hospitalId = obj.id;
  }

  @observable
  statusList = {
    0: "禁用",
    1: "正常"
  };

  /* 新增编辑科室表单 */
  @action
  updateDepartmentEditFormAction(obj) {
    this.departmentEditForm = obj;
  }

  @action
  toggleModalVisible() {
    this.modalVisible = !this.modalVisible;
  }

  /* 重置 */
  @action
  resetPageAction() {
    this.drawerVisible = false;
    this.new_departmentList = {};
    this.departmentEditForm = {};
    this.hospitalEditForm = {};
  }
}

export default new HospitalStore(actionMap);
