import { action, observable } from "mobx";
import {
  addChannelActivity,
  addCooperation,
  addDepartment,
  addGood,
  addHospital,
  deleteCooperation,
  detailCooperation,
  editChannelActivity,
  editCooperation,
  editCooperationStatus,
  editGood,
  fetchChannelGoodList,
  fetchCooperationList,
  fetchDepartmentList,
  fetchGoodChannelDetail,
  fetchGoodDetail,
  fetchGoodList,
  fetchGoodPackage,
  fetchTemplateList,
  getActivityDetail,
  getActivityModuleList,
  getActivitySyModuleList,
  getChannelActivityList,
  getMzProductList,
  getMzZdProductList,
  getSyProductList,
  getReportFocusList
} from "services/cooperate";

import ModelsBase from "utils/models";
import { message } from "antd";

const userFormTemplate = {
  name: undefined,
  mobile: undefined,
  role: undefined,
  hospital: undefined,
  contact_list: undefined,
  cooperation_list: undefined
};

const actionMap = {
  cooperationList: {
    api: fetchCooperationList,
    action: "list",
    dataFunction: (_this, key, data) => {
      _this.listData[key] = data.user_data;
      _this.userStatusList = data.status_list;
      _this.userRoleList = data.role_list;
      _this.cooperateList = data.cooperation_list;
      _this.hospitalList = data.hospital_list;
      _this.contactList = data.contact_list;
      _this.doctorList = data.doctor_list;
      _this.organizationList = data.organization_list;
      _this.channelSearchList = data.channel_search_list;
    },
    errorMessage: "获取合作渠道列表失败"
  },
  fetchDepartmentList: {
    api: fetchDepartmentList,
    actions: "list",
    dataFunction: (_this, key, data) => {
      _this.departmentList = data;
    },
    errorMessage: "获取科室列表失败"
  },
  editCooperationStatusAction: {
    api: editCooperationStatus,
    type: "post",
    dataFunction: () => {
      message.success("操作成功");
    },
    errorMessage: "编辑用户状态失败"
  },
  // fetchUserRoleListAction: {
  //   api: fetchUserRoleList,
  //   dataFunction: (_this, key, data) => {
  //     _this.userRoleList = data;
  //   },
  //   errorMessage: "获取用户角色失败"
  // },
  addCooperationAction: {
    api: addCooperation,
    type: "post",
    dataFunction: () => {
      message.success("新增合作渠道成功");
    },
    errorMessage: "新增合作渠道失败"
  },
  editCooperationAction: {
    api: editCooperation,
    type: "post",
    dataFunction: () => {
      message.success("编辑合作渠道信息成功");
    },
    errorMessage: "编辑合作渠道信息失败"
  },
  deleteCooperationAction: {
    api: deleteCooperation,
    type: "post",
    dataFunction: () => {
      message.success("删除合作渠道信息成功");
    },
    errorMessage: "删除合作渠道信息失败"
  },
  detailCooperationAction: {
    api: detailCooperation,
    type: "post",
    dataFunction: (_this, key, data) => {
      if (data.user_data && data.user_data.items) {
        _this.cooperateDetail = data.user_data.items[0];
      }
      message.success("获取合作渠道信息成功");
    },
    errorMessage: "获取合作渠道信息失败"
  },
  getReportFocusListAction: {
    api: getReportFocusList,
    type: "get",
    dataFunction: (_this, key, data) => {
      _this.reportFocusList = data || [];
    },
    errorMessage: "获取关注列表失败"
  },
  // 新增商品时候的下拉框
  fetchGoodListAction: {
    api: fetchGoodList,
    type: "list",
    dataFunction: (_this, key, data) => {
      _this.goodList = data.goods_list;
      _this.collectionType = data.collection_type;
    }
  },
  /* 获取新增商品页面某个商品的商品详情 */
  fetchGoodDetailAction: {
    api: fetchGoodDetail,
    type: "list",
    dataFunction: (_this, key, data) => {
      _this.goodDetail = data;
      _this.costPrice = data.cost_price;
    },
    errorMessage: "获取商品详情失败"
  },
  /* 获取新增商品页面某个商品的包装列表详情 */
  fetchGoodPackageAction: {
    api: fetchGoodPackage,
    type: "list",
    dataFunction: (_this, key, data) => {
      _this.packageList = data;
    }
  },
  addGoodAction: {
    api: addGood,
    type: "post",
    dataFunction: () => {
      message.success("新增商品成功");
    },
    errorMessage: "新增商品失败"
  },
  editGoodAction: {
    api: editGood,
    type: "post",
    dataFunction: () => {
      message.success("编辑商品成功");
    },
    errorMessage: "编辑商品失败"
  },
  goodChannelDetail: {
    api: fetchGoodChannelDetail,
    type: "get",
    dataFunction: (_this, key, data) => {
      if (data && data.report_type) {
        _this.goodChannelDetail = Object.assign({}, data, {
          report_type: JSON.parse(data.report_type)
        });
      } else {
        _this.goodChannelDetail = data;
      }
      if (data && data.report_push_type) {
        _this.goodChannelDetail = Object.assign({}, _this.goodChannelDetail, {
          report_push_type: JSON.parse(data.report_push_type)
        });
      }
      if (+data.is_export_report === 1) {
        _this.report = data.is_export_report;
      }
      if (+data.is_push_report === 1) {
        _this.isPush = data.is_push_report;
      }
      message.success("获取渠道商品详情成功");
    },
    errorMessage: "获取渠道商品详情失败"
  },
  /* 获取渠道商品列表 */
  channelGoodListAction: {
    api: fetchChannelGoodList,
    type: "list",
    dataFunction: (_this, key, data) => {
      _this.tableData = data;
      message.success("获取渠道商品列表成功");
    },
    errorMessage: "获取渠道商品列表失败"
  },
  // 新增商品时候的下拉框
  fetchTemplateAction: {
    api: fetchTemplateList,
    type: "list",
    dataFunction: (_this, key, data) => {
      _this.templateList = data;
    }
  },
  /************  配置活动相关接口  ***************/
  // 1、活动类型为试用类的试用商品列表
  getSyProductListAction: {
    api: getSyProductList,
    type: "get",
    dataFunction: (_this, key, data) => {
      _this.syProductList = data;
    },
    errorMessage: "获取试用商品列表失败"
  },
  // 2、活动类型为买赠类的指定商品列表
  getMzZdProductListAction: {
    api: getMzZdProductList,
    type: "get",
    dataFunction: (_this, key, data) => {
      _this.zdMzProductList = data;
    },
    errorMessage: "获取买赠活动类型指定商品列表失败"
  },
  // 3、活动类型为买赠类的，赠送信息处的商品列表
  getMzProductListAction: {
    api: getMzProductList,
    type: "get",
    dataFunction: (_this, key, data) => {
      _this.mzProductList = data;
    },
    errorMessage: "获取买赠活动类型商品列表失败"
  },
  // 4、新增活动配置
  addChannelActivityAction: {
    api: addChannelActivity,
    type: "post",
    dataFunction: () => {
      message.success("添加活动配置成功");
    },
    errorMessage: "新增活动配置失败"
  },
  // 5、活动商品报告模板列表
  getActivityModuleListAction: {
    api: getActivityModuleList,
    type: "get",
    // dataFunction: () => {
    //   message.success("添加活动配置成功");
    // },
    errorMessage: "获取活动商品模板列表失败"
  },

  // 试用类获取报告模板列表
  getActivitySyModuleListAction: {
    api: getActivitySyModuleList,
    type: "get",
    errorMessage: "获取活动商品模板列表失败"
  },
  // 6、渠道活动列表list活动配置列表页
  getChannelActivityListAction: {
    api: getChannelActivityList,
    type: "get",
    dataFunction: (_this, key, data) => {
      _this.channelTable = data;
      message.success("获取活动列表成功");
    },
    errorMessage: "获取活动商品模板列表失败"
  },
  // 7、获取渠道活动详情页信息
  getActivityDetailAction: {
    api: getActivityDetail,
    type: "get",
    dataFunction: (_this, key, data) => {
      _this.activityForm = data.config;
      _this.productForm = data.product_list;
    },
    errorMessage: "获取活动配置信息失败"
  },
  // 9、编辑活动配置
  editChannelActivityAction: {
    api: editChannelActivity,
    type: "post",
    dataFunction: () => {
      message.success("编辑活动配置成功");
    },
    errorMessage: "编辑活动配置失败"
  },
  // v2.3.2
  // 新增医院
  addHospitalAction: {
    api: addHospital,
    type: "post",
    errorMessage: "新增医院失败"
  },
  // 新增医院科室
  addDepartmentAction: {
    api: addDepartment,
    type: "post",
    errorMessage: "新增医院科室失败"
  }
};

class UserStore extends ModelsBase {
  @observable
  userStatusList = {};

  // v2.3.2更新渠道列表搜索条件
  @observable
  channelSearchList = {};

  /*** 商品成本价 */
  @observable
  costPrice = 0;

  // 该渠道的详情
  @observable
  cooperateDetail = {};

  // 擅长关注
  @observable
  reportFocusList = [];

  // 某个商品的详情
  @observable
  goodDetail = {};

  // 某渠道的tab信息
  @observable
  goodChannelDetail = {};

  // 商品列表
  @observable
  goodList = {};

  // 收款方式（新增编辑渠道商品用）
  @observable
  collectionType = "";

  // 报告模版列表
  @observable
  templateList = [];

  // 渠道商品table中的数据
  @observable
  tableData = {};

  // 包装列表
  @observable
  packageList = {};

  @observable
  userRoleList = {};

  /** 收样机构是否disabled */
  @observable
  hospitalDisabled = false;

  /** 合作方式列表  */
  @observable
  cooperateList = [];

  /** 收样机构列表 */
  @observable
  organizationList = {};

  /** 某个医院下的科室列表 */
  @observable
  departmentList = [];

  /** 收样医生列表  */
  @observable
  doctorList = {};

  /** 收样机构列表 */
  @observable
  hospitalList = {};

  /** 对接人列表 */
  @observable
  contactList = {};

  @observable
  roleType = "";

  /** 商品基础信息是否生成报告 */
  @observable
  report = "2";

  /** 是否推送报告 */
  @observable
  isPush = "2";

  /** 是否开启活动 */
  @observable
  activity = "";

  // 赠送活动页面的是否生成报告
  @observable
  reportAct = "";

  @observable
  addUserForm = Object.assign({}, userFormTemplate);

  // 更新cooperateDetail（用于新增医院和科室）
  @action
  updateCooperateDetail(data) {
    this.cooperateDetail = Object.assign({}, this.cooperateDetail, data);
  }

  /* 保存表单 */
  @action
  saveToUserFormAction(data) {
    this.addUserForm = Object.assign(this.addUserForm, data);
  }

  /* 重置表单和步骤 */
  @action
  resetAddUserAction() {
    this.addUserForm = Object.assign({}, userFormTemplate);
    this.roleType = "";
    this.cooperateDetail = {};
    this.goodChannelDetail = {};
    this.report = "2";
    this.isPush = "2";
    this.goodChannelDetail = {};
    this.costPrice = "";
  }

  /** 是否生成报告 */
  @action
  toggleReportType(value) {
    this.report = value;
  }
  /** 是否推送报告 */
  @action
  toggleReportPushType(value) {
    this.isPush = value;
  }
  /** 是否开启活动 */
  @action
  toggleActivityType(value) {
    this.activity = value;
  }
  /** 切换账户 */
  @action
  toggleRoleType(value) {
    this.roleType = value;
  }

  /** 是否开启活动的是否生成报告 */
  @action
  toggleActReport(value) {
    this.reportAct = value;
  }

  /* 开启关闭浮层 */
  @action
  toggleHospitalDisabled() {
    this.hospitalDisabled = !this.hospitalDisabled;
  }

  /******* 设置活动配置相关 ********/
  // 活动form
  @observable
  activityForm = {};

  // 活动配置的商品form
  @observable
  productForm = [];

  // 试用商品列表
  @observable
  syProductList = [];

  // 买赠商品列表
  @observable
  mzProductList = [];

  // 买赠类型指定商品列表
  @observable
  zdMzProductList = [];

  // 活动配置列表
  @observable
  channelTable = {};

  @action
  mergeActivityForm = values => {
    this.activityForm = Object.assign({}, this.activityForm, values);
  };

  @action
  updateProductForm = values => {
    this.productForm = values;
  };

  @action
  resetActivityPage = () => {
    this.activityForm = {};
    this.productForm = [];
  };
}

export default new UserStore(actionMap);
