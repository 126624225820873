import React, { Component } from "react";
import BlankLayout from "layouts/BlankLayout";
import CommonException from "components/CommonException";

class Exception extends Component {
  render() {
    return (
      <BlankLayout>
        <CommonException {...this.props} />
      </BlankLayout>
    );
  }
}

export default Exception;
