import request from "utils/request";

/** 获取交易笔数、交易金额 */
// export async function fetchMoney(obj) {
//   return request("/api/user/sale_data", {
//     method: "GET",
//     data: obj
//   });
// }

/** 获取趋势图 */
export async function fetchGraphData(obj) {
  return request("/api/user/trend_chart", {
    method: "GET",
    data: obj
  });
}

/** 获取商务推广数据 */
export async function fetchBDTable(obj) {
  return request("/api/user/get_channel_data", {
    method: "GET",
    data: obj
  });
}

/** 获取渠道推广数据 */
export async function fetchChannelTable(obj) {
  return request("/api/user/channel_data_account", {
    method: "GET",
    data: obj
  });
}

/** 获取整体概览数据 */
export async function fetchDataAccount(obj) {
  return request("/api/user/data_account", {
    method: "GET",
    data: obj
  });
}


// new
/** 获取整体概览数据 */
export async function fetchAccountChart(obj) {
  return request("/api/accounts/account_chart", {
    method: "GET",
    data: obj
  });
}

/** 商务统计接口 */
export async function fetchBusinessStatistics(obj) {
  return request("/api/accounts/business_statistics", {
    method: "GET",
    data: obj
  });
}


/** 渠道统计接口 */
export async function fetchChannelAccount(obj) {
  return request("/api/accounts/channel_account", {
    method: "GET",
    data: obj
  });
}