import { action, observable } from "mobx";
import {
  fetchDepartmentList,
  fetchSampleDetail,
  fetchSampleList,
  fetchSampleSum
} from "services/sample";

import ModelsBase from "utils/models";

const actionMap = {
  sampleList: {
    api: fetchSampleList,
    action: "list",
    dataFunction: (_this, key, data) => {
      _this.listData[key] = data.sample_data;
      _this.sampleStatusList = data.status_list;
      _this.hospitalList = data.hospital_list;
      _this.checkOrgList = data.check_org_list;
      _this.checkDepartmentList = data.check_department_list;
      _this.cooperateList = data.cooperate_list;
      _this.contactList = data.contact_list;
      _this.doctorList = data.doctor_list;
      _this.sampleTotal = data.sample_data.count_total;
      _this.current_column = data.sample_data.current_column.name;
      _this.channelSearchList = data.channel_search_list;
      _this.checkDoctorList = data.check_doctor_list;
      _this.detect_item_list = data.detect_item_list;
    },
    errorMessage: "获取样本列表失败"
  },
  departmentList: {
    api: fetchDepartmentList,
    action: "get",
    dataFunction: (_this, key, data) => {
      _this.departmentList = data;
    },
    errorMessage: "获取样本列表失败"
  },
  sampleSum: {
    api: fetchSampleSum,
    dataFunction: (_this, key, data) => {
      _this.sampleSumList = data;
    },
    errorMessage: "获取样本统计信息失败"
  },
  sampleDetail: {
    api: fetchSampleDetail,
    fetchFunction: data => {
      return data;
    },
    dataFunction: (_this, key, data) => {
      _this.sampleDetail = data.sample_data;
      _this.sampleStatus = data.sample_data && +data.sample_data.status;
    },
    errorMessage: "获取样本统计详情失败"
  }
};

class SampleStore extends ModelsBase {
  // v2.3.2更新渠道列表搜索条件
  @observable
  channelSearchList = {};

  // v2.3.2更新送检医生列表
  checkDoctorList = [];

  @observable
  sampleStatusList = {};

  @observable
  sampleSumList = {};

  @observable
  sampleTotal = 0;

  @observable
  sampleDetail = {};

  /** 收样医生列表 */
  @observable
  doctorList = {};

  /** 收样机构 */
  @observable
  hospitalList = {};

  // v2.3.2更新收样机构（搜索条件用）
  @observable
  checkOrgList = [];

  // 2.3.2更新科室列表
  @observable
  checkDepartmentList = [];

  /** 医院对应的科室列表 */
  @observable
  departmentList = [];

  /** 是否选择了科室 */
  @observable
  isHospital = true;

  /** 合作类型 */
  @observable
  cooperateList = {};

  /** 对接人列表 */
  @observable
  contactList = {};

  @observable
  current_column = "采样时间";

  /* 样本详情中的进度条的状态 */
  @observable
  sampleStatus = 0;

  @observable
  checkTypes = {
    0: "16s",
    1: "qPCR",
    2: "qPCR Plus"
  };

  @observable
  detect_item_list = {};

  /* 开启关闭浮层 */
  @action
  toggleHospitalDisabled(value) {
    if (value) {
      this.isHospital = false;
    }
  }

  @action
  resetPageAction() {
    this.sampleDetail = {};
    this.isHospital = true;
    this.departmentList = [];
  }
}

export default new SampleStore(actionMap);
