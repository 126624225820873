/** description: 密码组件
 *  last_modified: 2019-01-08
 */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Form, Input } from "antd";
import regExpList from "../../utils/regExp";

const FormItem = Form.Item;

const LOGIN_STORE = "loginStore";

@inject(LOGIN_STORE)
@observer
class Password extends Component {
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("new_pwd")) {
      callback("您输入的密码与上一次不一致");
    } else {
      callback();
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let FormItemLayout;
    if (this.props.type === "2") {
      FormItemLayout = {
        labelCol: {
          xs: { span: 3 },
          sm: { span: 3 }
        },
        wrapperCol: {
          xs: { span: 10 },
          sm: { span: 10 }
        }
      };
    }
    return (
      <div>
        <FormItem {...FormItemLayout}>
          {getFieldDecorator("new_pwd", {
            rules: [
              { required: true, message: "请输入新密码，至少8位，必须包含大小写字母和数字的组合" },
              { 
                min: 8, max: 20, 
                pattern: regExpList.password,
                message: "至少8位，必须包含大小写字母和数字的组合，可用特殊字符(~!@#$%^&*_)" 
              }
            ]
          })(
            <Input
              length="20"
              type="password"
              placeholder="至少8位，必须包含大小写字母和数字的组合，可用特殊字符(~!@#$%^&*_)"
            />
          )}
        </FormItem>
        <FormItem {...FormItemLayout}>
          {getFieldDecorator("new_pwd_confirm", {
            rules: [
              {
                required: true,
                message: "请再次确认您的密码"
              },
              {
                validator: this.compareToFirstPassword
              }
            ]
          })(<Input type="password" placeholder="确认密码" />)}
        </FormItem>
      </div>
    );
  }
}

export default Password;
