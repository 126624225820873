import { observable, action } from "mobx";

class UiStore {
  @observable
  slideOpen = false;

  @observable
  menuList = [];

  /* 收放侧边栏 */
  @action
  toggleSliderAction() {
    this.slideOpen = !this.slideOpen;
  }

  /* 处理响应式断点 */
  @action
  hanldeSlideBreakpointAction(broken) {
    this.slideOpen = broken;
  }

  /* 更新菜单 */
  @action
  updateMenuListAction(list) {
    this.menuList = list;
  }
}

export default new UiStore();
