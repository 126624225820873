import React, { Component } from "react";
import { Button } from "antd";
import styles from "./styles.module.css";

class CommonException extends Component {
  render() {
    return (
      <div>
        <div className={styles.exception}>
          <div className={styles.exception_pic} />
          <div className={styles.content}>
            <div className={styles.title}>{this.props.title}</div>
            <div className={styles.text}>{this.props.tips}</div>
            <Button href="/" type="primary">
              返回首页
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default CommonException;
